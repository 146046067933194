/* eslint-disable sonarjs/no-duplicate-string */
export default {
    studentCount: 5,
    currentpage: 1,
    nextPageURL: null,
    contentTypes: [
      {
        contentType: 'MASTERY',
        assignments: [
          {
            assignmentId: 4597481,
            title: 'Management of Care EAQ ',
            gradePoints: 1,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-06-30T03:59:00Z'
          },
          {
            assignmentId: 4597491,
            title: 'Safety & Infection Control EAQ (Intermediate)',
            gradePoints: 1,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-07-07T03:59:00Z'
          },
          {
            assignmentId: 4597498,
            title: 'Psychosocial Integrity EAQ ',
            gradePoints: 1,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-07-14T03:59:00Z'
          },
          {
            assignmentId: 4597500,
            title: 'Pharmacological & Parenteral Therapies EAQ ',
            gradePoints: 1,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-07-21T03:59:00Z'
          },
          {
            assignmentId: 4597502,
            title: 'Physiological Adaptation EAQ ',
            gradePoints: 1,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-07-21T03:59:00Z'
          },
          {
            assignmentId: 4597508,
            title: 'Reduction of Risk Potential EAQ ',
            gradePoints: 1,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-07-28T03:59:00Z'
          },
          {
            assignmentId: 4597518,
            title: 'Health Promotion & Maintenance EAQ ',
            gradePoints: 1,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-08-04T03:59:00Z'
          }
        ]
      },
      {
        contentType: 'QUIZ_BY_QUESTION',
        assignments: [
          {
            assignmentId: 4597488,
            title: 'Analgesic Therapy ',
            gradePoints: 20,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-06-30T03:59:00Z'
          },
          {
            assignmentId: 4785643,
            title: 'Week 4 Quiz- In Class ',
            gradePoints: 29,
            gradeType: 'SCORED',
            dueDate: '2024-07-19T03:59:00Z'
          }
        ]
      },
      {
        contentType: 'STANDARD',
        assignments: [
          {
            assignmentId: 4597495,
            title: 'Antiviral Drugs EAQ ',
            gradePoints: 5,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-07-07T03:59:00Z'
          },
          {
            assignmentId: 4597499,
            title: 'Coagulation Modifier Drugs Pharm EAQ ',
            gradePoints: 5,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-07-14T03:59:00Z'
          },
          {
            assignmentId: 4597505,
            title: 'Respiratory Drugs Pharm EAQ ',
            gradePoints: 5,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-07-21T03:59:00Z'
          },
          {
            assignmentId: 4597509,
            title: 'Antidysrhythmic Drugs EAQ ',
            gradePoints: 5,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-07-28T03:59:00Z'
          },
          {
            assignmentId: 4597520,
            title: 'Antibiotic Drugs EAQ ',
            gradePoints: 5,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-08-04T03:59:00Z'
          }
        ]
      },
      {
        contentType: 'LESSONS',
        assignments: [
          {
            assignmentId: 4597496,
            title: 'Antiviral and Antiretroviral Therapy',
            gradePoints: 100,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-07-07T03:59:00Z'
          }
        ]
      },
      {
        contentType: 'AUTHESS',
        assignments: [
          {
            assignmentId: 4597521,
            title: 'Critical Care Practice HESI',
            gradePoints: 12,
            gradeType: 'PASS_FAIL',
            dueDate: '2024-08-04T03:59:00Z'
          }
        ]
      }
    ],
    studentGrades: [
      {
        eolsId: 6952847,
        firstName: 'Jessica',
        lastName: 'Allbright',
        contentTypeGrades: [
          {
            contentType: 'STANDARD',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597495,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT5M18S',
                submissionDate: '2024-07-01T14:23:53.752Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597509,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT1M45S',
                submissionDate: '2024-07-27T01:21:34.016Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597520,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT3M59S',
                submissionDate: '2024-07-28T01:03:32.163Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597505,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT2M30S',
                submissionDate: '2024-07-18T13:33:55.392Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597499,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT2M29S',
                submissionDate: '2024-07-07T13:35:12.67Z',
                totalQuestionsAnswered: 5
              }
            ]
          },
          {
            contentType: 'QUIZ_BY_QUESTION',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4785643,
                grade: {
                  type: 'SCORED',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT10M14S',
                submissionDate: '2024-07-18T15:15:44.781Z',
                totalQuestionsAnswered: 29
              },
              {
                assignmentId: 4597488,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT34M46S',
                submissionDate: '2024-06-29T23:33:48.584Z',
                totalQuestionsAnswered: 20
              }
            ]
          },
          {
            contentType: 'MASTERY',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597508,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT3H36M52S',
                submissionDate: '2024-07-27T01:17:27.98Z',
                totalQuestionsAnswered: 149
              },
              {
                assignmentId: 4597491,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT23M58S',
                submissionDate: '2024-07-01T14:17:55.418Z',
                totalQuestionsAnswered: 33
              },
              {
                assignmentId: 4597502,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT0S',
                submissionDate: '2024-07-18T13:30:40.386Z',
                totalQuestionsAnswered: 0
              },
              {
                assignmentId: 4597518,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT3H29M44S',
                submissionDate: '2024-07-28T00:58:00.908Z',
                totalQuestionsAnswered: 129
              },
              {
                assignmentId: 4597500,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT3H48M46S',
                submissionDate: '2024-07-18T13:27:30.624Z',
                totalQuestionsAnswered: 93
              },
              {
                assignmentId: 4597498,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT3H27M1S',
                submissionDate: '2024-07-07T13:30:41.012Z',
                totalQuestionsAnswered: 166
              },
              {
                assignmentId: 4597481,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT6H8M50S',
                submissionDate: '2024-06-27T23:44:57.156Z',
                totalQuestionsAnswered: 307
              }
            ]
          },
          {
            contentType: 'AUTHESS',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597521,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT17M40S',
                submissionDate: '2024-07-28T01:22:02.3Z',
                totalQuestionsAnswered: 12
              }
            ]
          },
          {
            contentType: 'LESSONS',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597496,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT3M55S',
                submissionDate: '2024-07-07T13:43:07.572Z',
                totalQuestionsAnswered: 18
              }
            ]
          }
        ]
      },
      {
        eolsId: 3047711,
        firstName: 'Bennie',
        lastName: 'Factor',
        contentTypeGrades: [
          {
            contentType: 'STANDARD',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597495,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT1M57S',
                submissionDate: '2024-07-05T23:11:43.131Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597509,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT1M44S',
                submissionDate: '2024-07-25T13:15:50.572Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597520,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT2M10S',
                submissionDate: '2024-08-04T02:04:30.09Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597505,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT10M6S',
                submissionDate: '2024-07-18T12:31:16.879Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597499,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT1M50S',
                submissionDate: '2024-07-12T14:26:30.418Z',
                totalQuestionsAnswered: 5
              }
            ]
          },
          {
            contentType: 'MASTERY',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597508,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT9H57M18S',
                submissionDate: '2024-07-27T20:20:15.634Z',
                totalQuestionsAnswered: 182
              },
              {
                assignmentId: 4597491,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT17M13S',
                submissionDate: '2024-07-05T23:35:08.881Z',
                totalQuestionsAnswered: 6
              },
              {
                assignmentId: 4597502,
                grade: {
                  type: 'PASS_FAIL',
                  score: '0.5'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT0S',
                submissionDate: '2024-07-18T12:31:58.572Z',
                totalQuestionsAnswered: 0
              },
              {
                assignmentId: 4597518,
                grade: {
                  type: 'PASS_FAIL',
                  score: '0.49'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT3H1S',
                submissionDate: '2024-08-04T02:32:31.582Z',
                totalQuestionsAnswered: 161
              },
              {
                assignmentId: 4597500,
                grade: {
                  type: 'PASS_FAIL',
                  score: '0.5'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT11H8M24S',
                submissionDate: '2024-07-18T14:11:12.255Z',
                totalQuestionsAnswered: 123
              },
              {
                assignmentId: 4597498,
                grade: {
                  type: 'PASS_FAIL',
                  score: '0.4'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT4H45M39S',
                submissionDate: '2024-07-12T14:23:58.773Z',
                totalQuestionsAnswered: 146
              },
              {
                assignmentId: 4597481,
                grade: {
                  type: 'PASS_FAIL',
                  score: '0.9'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT7H24M59S',
                submissionDate: '2024-06-27T13:44:13.264Z',
                totalQuestionsAnswered: 328
              }
            ]
          },
          {
            contentType: 'QUIZ_BY_QUESTION',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4785643,
                grade: {
                  type: 'SCORED',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT13M18S',
                submissionDate: '2024-07-18T15:16:38.795Z',
                totalQuestionsAnswered: 29
              },
              {
                assignmentId: 4597488,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT36M34S',
                submissionDate: '2024-06-27T14:35:45.618Z',
                totalQuestionsAnswered: 20
              }
            ]
          },
          {
            contentType: 'AUTHESS',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597521,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT27M21S',
                submissionDate: '2024-08-04T02:00:10.433Z',
                totalQuestionsAnswered: 12
              }
            ]
          },
          {
            contentType: 'LESSONS',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597496,
                grade: {
                  type: 'PASS_FAIL',
                  score: '0.68'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT13M36S',
                submissionDate: '2024-07-05T23:27:04.382Z',
                totalQuestionsAnswered: 18
              }
            ]
          }
        ]
      },
      {
        eolsId: 5167001,
        firstName: 'Jacqueline',
        lastName: 'Payeur',
        contentTypeGrades: [
          {
            contentType: 'QUIZ_BY_QUESTION',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4785643,
                grade: {
                  type: 'SCORED',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT12M56S',
                submissionDate: '2024-07-18T15:15:44.535Z',
                totalQuestionsAnswered: 29
              },
              {
                assignmentId: 4597488,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT6M53S',
                submissionDate: '2024-06-28T01:33:45.961Z',
                totalQuestionsAnswered: 20
              }
            ]
          },
          {
            contentType: 'LESSONS',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597496,
                grade: {
                  type: 'PASS_FAIL',
                  score: '0.5'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT1M6S',
                submissionDate: '2024-07-02T21:36:30.794Z',
                totalQuestionsAnswered: 18
              }
            ]
          },
          {
            contentType: 'MASTERY',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597508,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT16M52S',
                submissionDate: '2024-07-18T14:14:11.53Z',
                totalQuestionsAnswered: 73
              },
              {
                assignmentId: 4597491,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT6M10S',
                submissionDate: '2024-07-02T21:30:50.744Z',
                totalQuestionsAnswered: 47
              },
              {
                assignmentId: 4597518,
                grade: {
                  type: 'PASS_FAIL',
                  score: '0'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT1H41S',
                submissionDate: '2024-07-31T20:43:19.396Z',
                totalQuestionsAnswered: 252
              },
              {
                assignmentId: 4597502,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT0S',
                submissionDate: '2024-07-16T19:17:40.301Z',
                totalQuestionsAnswered: 0
              },
              {
                assignmentId: 4597500,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT35M23S',
                submissionDate: '2024-07-16T19:17:10.754Z',
                totalQuestionsAnswered: 194
              },
              {
                assignmentId: 4597498,
                grade: {
                  type: 'PASS_FAIL',
                  score: '0'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT33M54S',
                submissionDate: '2024-07-08T20:16:36.318Z',
                totalQuestionsAnswered: 187
              },
              {
                assignmentId: 4597481,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT38M30S',
                submissionDate: '2024-06-27T12:36:21.185Z',
                totalQuestionsAnswered: 112
              }
            ]
          },
          {
            contentType: 'AUTHESS',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597521,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT17M30S',
                submissionDate: '2024-08-01T12:37:24.497Z',
                totalQuestionsAnswered: 12
              }
            ]
          },
          {
            contentType: 'STANDARD',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597495,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT25S',
                submissionDate: '2024-07-02T21:37:20.328Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597509,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT38S',
                submissionDate: '2024-07-18T14:15:20.379Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597520,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT36S',
                submissionDate: '2024-08-01T12:19:24.161Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597505,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT24S',
                submissionDate: '2024-07-16T19:18:30.915Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597499,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT49S',
                submissionDate: '2024-07-08T20:18:10.059Z',
                totalQuestionsAnswered: 5
              }
            ]
          }
        ]
      },
      {
        eolsId: 5846998,
        firstName: 'Alexander',
        lastName: 'Tushishvili',
        contentTypeGrades: [
          {
            contentType: 'STANDARD',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597495,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT20S',
                submissionDate: '2024-07-08T01:48:55.279Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597509,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT2M33S',
                submissionDate: '2024-07-18T14:18:14.675Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597520,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT38S',
                submissionDate: '2024-07-18T14:20:31.856Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597505,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT29S',
                submissionDate: '2024-07-18T12:04:38.438Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597499,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT1M3S',
                submissionDate: '2024-07-13T03:20:31.273Z',
                totalQuestionsAnswered: 5
              }
            ]
          },
          {
            contentType: 'MASTERY',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597508,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT3H6M4S',
                submissionDate: '2024-07-18T14:58:59.71Z',
                totalQuestionsAnswered: 166
              },
              {
                assignmentId: 4597491,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT0S',
                submissionDate: '2024-07-08T01:49:12.225Z',
                totalQuestionsAnswered: 0
              },
              {
                assignmentId: 4597518,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT2H3M50S',
                submissionDate: '2024-08-01T12:41:56.912Z',
                totalQuestionsAnswered: 95
              },
              {
                assignmentId: 4597502,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT2H19M28S',
                submissionDate: '2024-07-18T12:28:52.076Z',
                totalQuestionsAnswered: 85
              },
              {
                assignmentId: 4597500,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT1H14M39S',
                submissionDate: '2024-07-18T12:14:39.201Z',
                totalQuestionsAnswered: 68
              },
              {
                assignmentId: 4597498,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT3H22M57S',
                submissionDate: '2024-07-13T03:18:52.946Z',
                totalQuestionsAnswered: 150
              },
              {
                assignmentId: 4597481,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT5H43M9S',
                submissionDate: '2024-06-27T12:51:29.328Z',
                totalQuestionsAnswered: 273
              }
            ]
          },
          {
            contentType: 'LESSONS',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597496,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT2M50S',
                submissionDate: '2024-07-08T01:36:59.317Z',
                totalQuestionsAnswered: 18
              }
            ]
          },
          {
            contentType: 'QUIZ_BY_QUESTION',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4785643,
                grade: {
                  type: 'SCORED',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT11M55S',
                submissionDate: '2024-07-18T15:15:39.083Z',
                totalQuestionsAnswered: 29
              },
              {
                assignmentId: 4597488,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT1M55S',
                submissionDate: '2024-06-27T12:56:36.784Z',
                totalQuestionsAnswered: 20
              }
            ]
          },
          {
            contentType: 'AUTHESS',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597521,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT10M8S',
                submissionDate: '2024-08-01T12:29:41.726Z',
                totalQuestionsAnswered: 12
              }
            ]
          }
        ]
      },
      {
        eolsId: 6037438,
        firstName: 'MacKenzie',
        lastName: 'Wagner',
        contentTypeGrades: [
          {
            contentType: 'STANDARD',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597495,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT1M19S',
                submissionDate: '2024-06-27T14:15:37.653Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597509,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT1M55S',
                submissionDate: '2024-07-24T23:42:15.471Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597520,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT1M24S',
                submissionDate: '2024-07-30T14:50:43.283Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597505,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT8M21S',
                submissionDate: '2024-07-16T00:56:57.244Z',
                totalQuestionsAnswered: 5
              },
              {
                assignmentId: 4597499,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT3M13S',
                submissionDate: '2024-07-07T22:11:41.269Z',
                totalQuestionsAnswered: 5
              }
            ]
          },
          {
            contentType: 'LESSONS',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597496,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT2M',
                submissionDate: '2024-06-27T14:13:48.597Z',
                totalQuestionsAnswered: 18
              }
            ]
          },
          {
            contentType: 'AUTHESS',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597521,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT12M12S',
                submissionDate: '2024-07-30T15:03:20.383Z',
                totalQuestionsAnswered: 12
              }
            ]
          },
          {
            contentType: 'MASTERY',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4597508,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT3H27M38S',
                submissionDate: '2024-07-24T23:39:37.287Z',
                totalQuestionsAnswered: 184
              },
              {
                assignmentId: 4597491,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT46M39S',
                submissionDate: '2024-06-27T14:11:07.356Z',
                totalQuestionsAnswered: 69
              },
              {
                assignmentId: 4597518,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT4H21M23S',
                submissionDate: '2024-07-30T14:48:33.596Z',
                totalQuestionsAnswered: 195
              },
              {
                assignmentId: 4597502,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT0S',
                submissionDate: '2024-07-16T00:47:54.918Z',
                totalQuestionsAnswered: 0
              },
              {
                assignmentId: 4597500,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT3H38M41S',
                submissionDate: '2024-07-16T00:47:14.7Z',
                totalQuestionsAnswered: 127
              },
              {
                assignmentId: 4597498,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT3H15M46S',
                submissionDate: '2024-07-07T22:07:19.075Z',
                totalQuestionsAnswered: 161
              },
              {
                assignmentId: 4597481,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT5H5M43S',
                submissionDate: '2024-06-24T21:17:42.643Z',
                totalQuestionsAnswered: 354
              }
            ]
          },
          {
            contentType: 'QUIZ_BY_QUESTION',
            grade: null,
            assignmentGrades: [
              {
                assignmentId: 4785643,
                grade: {
                  type: 'SCORED',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT12M28S',
                submissionDate: '2024-07-18T15:15:43.375Z',
                totalQuestionsAnswered: 29
              },
              {
                assignmentId: 4597488,
                grade: {
                  type: 'PASS_FAIL',
                  score: '1'
                },
                progressStatus: 'COMPLETED',
                timeSpent: 'PT17M15S',
                submissionDate: '2024-06-26T18:12:41.159Z',
                totalQuestionsAnswered: 20
              }
            ]
          }
        ]
      }
    ]
  };
  