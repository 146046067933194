import { isEmpty, keyBy } from 'lodash';
import { ScalePieChartData, ScalePieChartConfig } from 'components/common/scale-pie-chart/ScalePieChart';
import { DistributionBarChartDataProps, DistributionBarChartConfigProps } from 'components/common/distribution-bar-chart/DistributionBarChart';
import { SHERPATH_COURSE_TYPES } from 'constants/app.constant';
import { EvolveProductTypeKey } from 'constants/externalApp.constant';
import { CourseAggregateDTO, CourseSection, StudentOverallScore, CourseAggregateData } from 'reports/cw/models';
import { CourseSectionInfo } from 'reports/had/models';
import { DISTRIBUTION_OF_SCORES_RANGES } from 'reports/cw/constants/chart.constant';
import { COURSE_TYPE } from '../constants/cw.constant';

const fillExtendedOrangeClassName = 'u-els-fill-extended-orange-7';
const fillRemainingGreenClassName = 'u-els-fill-custom-extended-green-2';

export const coursePieChartConfig: ScalePieChartConfig = Object.freeze({
  cx: 35,
  cy: 35,
  paddingAngle: 5,
  startAngle: 90,
  endAngle: -270
});
export const courseDistributionChartConfig: DistributionBarChartConfigProps = Object.freeze({
  barSize: 65,
  xAxisKey: 'name',
  yAxisKey: 'value',
  margin: { top: 5, right: 0, bottom: 0, left: 31 },
  shouldUseBorderLabel: true
});

export const sherpathCourseDistributionChartConfig: DistributionBarChartConfigProps = Object.freeze({
  ...courseDistributionChartConfig,
  margin: { top: 5, right: 0, bottom: 5, left: 45 },
  unit: '%',
  referenceLineLabels: [0, 50, 100]
});

const ChartDataId = {
  remaining: 'remaining',
  disengagedStudents: 'disengagedStudents',
  lateAssignmentStudents: 'lateAssignmentStudents',
  averageScore: 'averageScore'
};

export const courseTypeChartLabel = Object.values(COURSE_TYPE);

export const isSherpathClassicByCourseType = (courseType: string): boolean => {
  return SHERPATH_COURSE_TYPES.some(appId => appId === courseType);
};

export const isSherpathClassicCourse = (course: CourseAggregateDTO): boolean => {
  return !!course?.sherpathCourse;
};

export const getCourseName = (selectedCourseId: number, coursesInfo: CourseSection[] = []): string => coursesInfo.find(course => course.id === selectedCourseId)?.courseName || '';
export const getDisengagedStudentChartData = ({ disengagedStudents, totalStudent }: CourseAggregateDTO): ScalePieChartData[] => [
  { id: ChartDataId.disengagedStudents, value: disengagedStudents, colorClassName: fillExtendedOrangeClassName },
  { id: ChartDataId.remaining, value: totalStudent - disengagedStudents, colorClassName: fillRemainingGreenClassName }
];

export const geLateAssignmentStudentChartData = ({ numberOfStudentWithAnyIncompleteAssignment, totalStudent }: CourseAggregateDTO): ScalePieChartData[] => [
  { id: ChartDataId.lateAssignmentStudents, value: numberOfStudentWithAnyIncompleteAssignment, colorClassName: fillExtendedOrangeClassName },
  { id: ChartDataId.remaining, value: totalStudent - numberOfStudentWithAnyIncompleteAssignment, colorClassName: fillRemainingGreenClassName }
];

export const getAverageScoreChartData = ({ averageScore }: CourseAggregateDTO): ScalePieChartData[] => [
  { id: ChartDataId.averageScore, value: averageScore, colorClassName: fillExtendedOrangeClassName },
  { id: ChartDataId.remaining, value: 100 - averageScore, colorClassName: fillRemainingGreenClassName }
];

export const getScoreLabelByOverallScore = (overallScore: number): string => {
  const percentOverallScore = overallScore * 100;
  const scoreRangeArray = Object.values(DISTRIBUTION_OF_SCORES_RANGES).map(value => ({ label: value.label, min: value.value.min, max: value.value.max }));
  const foundRange = scoreRangeArray.find(range => range.min <= percentOverallScore && range.max >= percentOverallScore) || { label: '' };
  return foundRange.label;
};

export const getDistributionOfScoreChartData = (studentOverallScores: StudentOverallScore[]): DistributionBarChartDataProps[] => {
  const { FROM_0_TO_54, FROM_55_TO_64, FROM_65_TO_74, FROM_75_TO_84, FROM_85_TO_100 } = DISTRIBUTION_OF_SCORES_RANGES;
  const numberOfStudentHaveScore = {
    [FROM_0_TO_54.label]: 0,
    [FROM_55_TO_64.label]: 0,
    [FROM_65_TO_74.label]: 0,
    [FROM_75_TO_84.label]: 0,
    [FROM_85_TO_100.label]: 0
  };

  studentOverallScores.forEach(({ overallScore = 0 }) => {
    const scoreLabel = getScoreLabelByOverallScore(overallScore);
    if (!isEmpty(scoreLabel)) {
      numberOfStudentHaveScore[scoreLabel] += 1;
    }
  });

  return [
    {
      name: DISTRIBUTION_OF_SCORES_RANGES.FROM_0_TO_54.label,
      value: numberOfStudentHaveScore[FROM_0_TO_54.label],
      fillColorClassName: 'u-els-fill-custom-old-brick'
    },
    {
      name: DISTRIBUTION_OF_SCORES_RANGES.FROM_55_TO_64.label,
      value: numberOfStudentHaveScore[FROM_55_TO_64.label],
      fillColorClassName: 'u-els-fill-custom-orange-2'
    },
    {
      name: DISTRIBUTION_OF_SCORES_RANGES.FROM_65_TO_74.label,
      value: numberOfStudentHaveScore[FROM_65_TO_74.label],
      fillColorClassName: 'u-els-fill-custom-bright-sun'
    },
    {
      name: DISTRIBUTION_OF_SCORES_RANGES.FROM_75_TO_84.label,
      value: numberOfStudentHaveScore[FROM_75_TO_84.label],
      fillColorClassName: 'u-els-fill-custom-green-2'
    },
    {
      name: DISTRIBUTION_OF_SCORES_RANGES.FROM_85_TO_100.label,
      value: numberOfStudentHaveScore[FROM_85_TO_100.label],
      fillColorClassName: 'u-els-fill-chart-green-3'
    }
  ];
};

export const getDistributionOfAverageScoreByTypeChartData = (averageScoresByTypes: CourseAggregateDTO['averageScoreByAssignmentTypes']): DistributionBarChartDataProps[] => {
  const sortedAverageScoresByTypes = [...averageScoresByTypes].sort((a, b) => b.averageScore - a.averageScore);
  const topAverageScoresByTypes = sortedAverageScoresByTypes.slice(0, 3);
  return topAverageScoresByTypes.map(score => ({
    name: (courseTypeChartLabel.find(label => score.assignmentType.toLowerCase() === label.value.toLowerCase()) || { display: '' }).display,
    value: score.averageScore,
    fillColorClassName: 'u-els-fill-info'
  }));
};

export const getCourseSectionIsbns = (courseSection: CourseSection) => {
  const entitlements = courseSection.entitlements
    .map(entitlement => {
      if (!entitlement || !entitlement.data) {
        return {
          entitlement,
          evolveProduct: null
        };
      }

      return {
        entitlement,
        evolveProduct: JSON.parse(entitlement?.data)
      };
    })
    .filter(item => {
      return item.evolveProduct;
    });

  const filteredEntitlements = entitlements.filter(entitlement => {
    if (!entitlement.evolveProduct) {
      return false;
    }

    return [
      EvolveProductTypeKey.SHERPATH_IA,
      EvolveProductTypeKey.SHERPATH_BOOK_ORGANIZED,
      EvolveProductTypeKey.SHADOW_HEALTH,
      EvolveProductTypeKey.EAQNG_IA,
      EvolveProductTypeKey.SIMULATION_SIM_CHART_NG,
      EvolveProductTypeKey.HESI_CASE_STUDY,
      EvolveProductTypeKey.HESI_PATIENT_REVIEW,
      EvolveProductTypeKey.HESI_PRACTICE_TEST,
      EvolveProductTypeKey.NURSING_CONCEPTS_IA
    ].includes(entitlement.evolveProduct.productTypeKey);
  });

  return filteredEntitlements.map(entitlement => entitlement.entitlement.isbn.toString());
};

export const buildCourseData = (courses: CourseAggregateDTO[], courseSections: CourseSectionInfo[]): CourseAggregateData[] => {
  const courseSectionMap = keyBy(courseSections, 'id');
  return (courses || []).map(course => ({
    ...course,
    courseName: courseSectionMap[course.courseSectionId]?.courseName || ''
  }));
};
