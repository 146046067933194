import { TableColumn, CustomHeader, HeaderGroup } from './ResponsiveTable';

const DEFAULT_COL_MIN_WIDTH = 150;

export const getColumnsGroupByCustomHeader = <T extends unknown>(columns: TableColumn<T>[], customHeaders: CustomHeader[]): Record<string, HeaderGroup<T>> => {
  let groupIndex = 1;
  let currentGroup = null;
  let currentTitle = '';
  const groupedColumns = {};
  const customGroups = new Map();

  customHeaders.forEach(group => {
    Array.from({ length: group.toIndex - group.fromIndex + 1 }).forEach((_, i) => {
      customGroups.set(group.fromIndex + i, group.title);
    });
  });

  columns.slice(1).forEach((col, index) => {
    const actualIndex = index + 1;
    const title = customGroups.get(actualIndex) || '';

    if (title !== currentTitle || !currentGroup) {
      currentGroup = `column${groupIndex}`;
      groupIndex += 1;
      groupedColumns[currentGroup] = { title, columns: [] };
      currentTitle = title;
    }

    if (!groupedColumns[currentGroup]) {
      groupedColumns[currentGroup] = { title, columns: [] };
    }

    groupedColumns[currentGroup].columns.push(col);
  });

  return groupedColumns;
};

export const getCustomHeaderGridTemplate = <T extends unknown>(columnsGroupByCustomHeader: Record<string, HeaderGroup<T>>): string =>
  Object.entries(columnsGroupByCustomHeader)
    .map(([, value]) => {
      const sumOfMinWidth = value.columns.filter(column => !column.hidden).reduce((sum, column) => sum + (column.minWidth || DEFAULT_COL_MIN_WIDTH), 0);
      return `${sumOfMinWidth}fr`;
    })
    .join(' ');

export const getRowMinWidth = <T extends unknown>(columns: TableColumn<T>[]): number =>
  columns.filter(column => !column.hidden).reduce((sum, column) => sum + (column.minWidth || DEFAULT_COL_MIN_WIDTH), 0);

export const getFirstColumnMinWidth = <T extends unknown>(firstColumn: TableColumn<T>): number => firstColumn.minWidth || DEFAULT_COL_MIN_WIDTH;

export const getColumnGridTemplate = <T extends unknown>(columns: TableColumn<T>[]): string =>
  columns
    .filter(column => !column.hidden)
    .map(column => `${getFirstColumnMinWidth(column)}fr`)
    .join(' ');

export const getHeaderScrollableGTC = <T extends unknown>(columns: TableColumn<T>[]): string => getColumnGridTemplate(columns.slice(1));
