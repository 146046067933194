export interface AssignmentEngagementMetricDTO {
  courseSectionId: number;
  avgTimeSpentPerQuestion: string;
  assignmentId: number;
  assignmentName: string;
  assignmentType: string;
  assignmentGradeType: string;
  avgScore: number;
  gradePoints?: number;
  percentAvgScore: number;
  avgTimeSpent: string;
  avgQuestionsAnsweredCorrect: number;
  avgQuestionsAnsweredIncorrect: number;
  percentComplete: number;
  totalStudentsNotStarted: number;
  totalStudentsInProgress: number;
  totalStudentsCompleted: number;
  totalStudentsPastDue: number;
  totalStudents: number;
  dueDate: string;
  isHaveScore?: boolean;
}

export interface AssignmentEngagementCard {
  below85percentAssignments: number;
  belowClassAvgStudents: number;
  completedAssignments: number;
  classAvgScore: number;
  inProgressAssignment: number;
  pastDueAssignments: number;
  pastDueStudents: number;
  totalAssignments: number;
  totalStudents: number;
}
export interface AssignmentEngagementDTO {
  cardData: AssignmentEngagementCard;
  courseSectionId: number;
  contentType: string;
  metrics: AssignmentEngagementMetricDTO[];
  listContentTypeAvailable: string[];
}

export interface AssignmentDetail {
  eolsUserDto: {
    id: number;
    lmsExternalId: string;
    externalId: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    type: string;
  };
  submitTime: string;
  score: number;
  grade: string;
  timeSpent: number;
  questionCount: {
    totalAnswered: number;
    totalCorrectAnswered: number;
    totalIncorrectAnswered: number;
  };
  status: string;
}

export interface AssignmentInfo {
  assignmentOptions: Array<object>;
  userId: number;
  elsAssignmentId: string;
  lmsAssignmentGuid: string;
  createdAt: string;
  updatedAt: string;
  sendResultToGradebook: boolean;
  gradePoints: number;
  students: Array<number>;
  assignmentGoals: Array<object>;
  assignmentTopics: Array<object>;
  assignmentQuestions: Array<object>;
  studentAssignments: Array<object>;
  courseSectionId: number;
  title: string;
  targetType: string;
  availableDate: string;
  dueDate: string;
  endDate: string;
  assignmentGradeType: string;
  assignmentScoreType: string;
  assignmentType: string;
  studyMode: boolean;
  isbn: string;
}

export interface AssignmentPerformance {
  id: number;
  name: string;
  quizType: string;
  goals: Array<number>;
  dueDate: string;
  questionAnswered: {
    averageAnswered: number;
    averageCorrect: number;
    averageIncorrect: number;
  };
  assignmentStatus: {
    notStarted: number;
    inProgress: number;
    completed: number;
    pastDue: number;
    percentComplete: number;
  };
  averageScore: number;
  gradeType?: string;
}

export interface StudentEngagementMetricDTO {
  courseSectionId: number;
  userId: number;
  firstName: string;
  lastName: string;
  contentType: string;
  percentAvgScore: number;
  avgScore: number;
  totalScore?: number;
  totalAssignmentQuestionsAnswered: number;
  totalSelfStudyQuestionsAnswered: number;
  avgTimeSpent?: string;
  avgTimeSpentOnQuestions: string;
  avgTimeSpentOnQuestionsDelta: string;
  totalAssignmentsPastDue: number;
  totalAssignments: number;
  totalAssignmentsComplete: number;
  lastLogin: string;
  totalTimeSpent: string;
  totalAssignmentsBelowClassAvg: number;
  isAtRiskStudent: boolean;
  totalGradePoints?: number;
}

export interface StudentEngagementDTO {
  courseSectionId: number;
  contentType: string;
  avgScoreOfClass: number;
  avgTimeOfClassOnQuestions: string;
  metrics: StudentEngagementMetricDTO[];
}

export enum StudentDetailAssignmentStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

export const ASSIGNMENT_SCORING_TYPE_MAPPING = Object.freeze({
  PASS_FAIL: 'Pass/Fail',
  NOT_GRADED: 'Not Graded',
  SCORED: 'Scored'
});

export const ASSIGNMENT_TYPE_MAPPING = Object.freeze({
  MASTERY: 'EAQ Mastery',
  STANDARD: 'EAQ Custom Quiz',
  QUIZ_BY_QUESTION: 'EAQ Custom Quiz by Question',
  AUTHESS: 'AUTHESS',
  CHART: 'CHART'
});

export interface StudentDetailAssignment {
  assignmentId: number;
  contentType: string;
  dueDate: string;
  gradeType: GradeType | string;
  pastDue: boolean;
  progressStatus: StudentDetailAssignmentStatusEnum;
  score: number;
  title: string;
  gradePoints?: number;
  timeSpent: string;
}

export interface StudentDetailData {
  courseSectionId: number;
  firstName: string;
  lastName: string;
  studentId: number;
  metrics: StudentDetailAssignment[];
}

export interface CourseSection {
  id: number;
  courseId: string;
  externalId: string;
  lmsClientId: string;
  consumerKey: string;
  lmsExternalId: string;
  courseName: string;
  gradeSyncVersion: number;
  courseActive?: boolean;
  activeCourseWithRecentActivity?: boolean;
  updatedAt?: string | number;
  createdAt?: string | number;
  courseCreationDate?: string | number;
  instructorId?: string;
  institution: {
    id: number;
    name: string;
  };
  entitlements?: {
    data: string;
    id: number;
    isbn: string;
    type: string;
    evolveProductTypeKey?: string;
    appId?: string;
  }[];
}

export interface GradebookData {
  studentCount: number;
  currentpage: number;
  nextPageURL: string;
  contentTypes: ContentType[];
  studentGrades: StudentGrade[];
}

interface ContentType {
  contentType: string;
  assignments: Assignment[];
}

interface Assignment {
  assignmentId: number;
  title: string;
  dueDate: string;
  gradeType: GradeType;
  gradePoints?: number;
}

interface StudentGrade {
  eolsId: number;
  firstName: string;
  lastName: string;
  contentTypeGrades: ContentTypeGrade[];
}

interface ContentTypeGrade {
  contentType: string;
  grade: Grade;
  assignmentGrades: AssignmentGrade[];
}

interface AssignmentGrade {
  assignmentId: number;
  grade: Grade;
  timeSpent?: string;
  progressStatus?: string;
  submissionDate?: string;
  totalQuestionsAnswered?: number;
}

interface Grade {
  type: GradeType;
  score: string;
}

export enum GradeType {
  PASS_FAIL = 'PASS_FAIL',
  SCORED = 'SCORED',
  UPGRADED = 'UPGRADED',
  MASTERY = 'MASTERY',
  NOT_GRADED = 'NOT_GRADED'
}

export interface DashboardProgressingDTO {
  courseSectionId: number;
  totalAssignment: number;
  completedAssignment: number;
  totalStudent: number;
  studentsAboveAverage: number;
  disengagedStudents: number;
}

export interface AssignmentSummaryMetric {
  assignmentId: number;
  assignmentName: string;
  assignmentType: string;
  assignmentGradeType: string;
  dueDate: string;
  percentComplete: number;
  totalStudentsNotStarted: number;
  gradePoints: number;
  avgScore: number;
  percentAvgScore: number;
  avgTimeSpent: string;
  totalStudents: number;
  studentsBelowAvgScore: number;
  maxTimeSpent: string;
  minTimeSpent: string;
}

export interface AssignmentSummaryCard {
  totalAssignments: number;
  totalCompletedAssignments: number;
  highPerformanceStudents: number;
  totalStudents: number;
  disengagedStudents: number;
}

export interface AssignmentSummary {
  card: AssignmentSummaryCard;
  metrics: AssignmentSummaryMetric[];
}

export interface EngagementByAssignmentType {
  assignmentType: string;
  totalCompletedAssignment: number;
  totalAssignments: number;
  totalAssignmentsPastDue: number;
}

export interface StudentOverallScore {
  eolsId: number;
  overallScore: number;
}

export interface CourseAggregateDTO {
  courseName?: string;
  courseSectionId: number;
  courseActive: boolean;
  courseCreationDate: string;
  completedSelfStudyQuestionsEAQ: number;
  disengagedStudents: number;
  totalStudent: number;
  engagementByAssignmentTypes: EngagementByAssignmentType[];
  studentOverallScores: StudentOverallScore[];
  numberOfStudentWithAnyIncompleteAssignment: number;
  averageScore: number;
  averageScoreByAssignmentTypes: { assignmentType: string; averageScore: number }[];
  sherpathCourse?: boolean;
  isEmpty?: boolean;
  isCSCourse?: boolean;
}

export interface CourseAggregateData extends CourseAggregateDTO {
  courseName: string;
}

export interface StudentHomeReportMetric {
  assignmentId: number;
  assignmentName: string;
  assignmentType?: string;
  classAvgScore: number;
  classAvgTimeSpent: string;
  dueDate: string;
  gradeType: string;
  gradePoints?: number;
  pastDue: boolean;
  score: number;
  status: string;
  timeSpent: string;
  totalQuestionsAnswered: number;
  numOfAttempts: number;
  performanceCompareClassAvg?: number;
}

export interface StudentHomeReportDTO {
  courseSectionId: number;
  firstName: string;
  lastName: string;
  noviceOrBelowTopicCount: number;
  studentId: number;
  topicCount: number;
  metrics: StudentHomeReportMetric[];
}

export interface AssignmentType {
  id: string;
  name: string;
  group: string;
  icon: string;
  isLinkedToOutsideApp?: boolean;
}

export interface EngagementSummaryDTO {
  courseSectionId: number;
  completedAssignments: number;
  pastDueAssignments: number;
  inProgressAssignments: number;
  notStartedAssignments: number;
  totalAssignments: number;
  completedSelfStudyQuestionsEAQ: number;
  typeCardsList: {
    assignmentType: string;
    assignmentCompletionRate: number;
    totalStudentsPastDue: number;
    totalStudents: number;
    totalAssignmentsByType: number;
    avgTimeSpentPerQuestion: string;
    avgTimeSpent: string;
    percentAvgScore: number;
    avgScore: number;
    avgSelfStudyQuestionsAttempted: number;
  }[];
}

export interface CWAssignment {
  id: number;
  contentId: string;
}

export interface StudentHomeAssignmentReportDTO {
  attempt: number;
  assignmentId: number;
  type: string;
  score: number;
  progressStatus: string;
  timeSpent: string;
  submissionDate: string;
  gradePoints: number;
}
