import moment from 'moment';
import { SORT_DIRECTION } from 'constants/app.constant';
import { DateRangeOption } from 'reports/hesi-ng/models';

export const HESI_NG_PATHS = {
  hesiNGExamResults: '/hesiExamResults',
  hesiNGExamResultsStudentDetail: '/hesiExamResults/studentDetail/:examId',
  hesiNGStudentExamResults: '/hesiExamResults/student'
};

export const FILTER_OPTIONS_EXAM_DATE_RANGE = {
  lastMonth: '1',
  lastThreeMonths: '3',
  lastSixMonths: '6',
  lastYear: '12',
  chooseSpecificDateRange: 'CHOOSE_SPECIFIC_DATE_RANGE'
};

export const FILTER_OPTIONS_EXAM_DATE_RANGE_PARAM_FOR_HESI = {
  [FILTER_OPTIONS_EXAM_DATE_RANGE.lastMonth]: '1',
  [FILTER_OPTIONS_EXAM_DATE_RANGE.lastThreeMonths]: '3',
  [FILTER_OPTIONS_EXAM_DATE_RANGE.lastSixMonths]: '6',
  [FILTER_OPTIONS_EXAM_DATE_RANGE.lastYear]: '12',
  [FILTER_OPTIONS_EXAM_DATE_RANGE.chooseSpecificDateRange]: 'CHOOSE_SPECIFIC_DATE_RANGE'
};

export const FILTER_OPTIONS_PARAM_FOR_HESI = {
  ALL_GROUPS: 'ALL_GROUPS',
  MULTIPLE_GROUPS: 'MULTIPLE_GROUPS',
  ALL_STUDENTS: 'ALL_STUDENTS',
  CHOOSE_SPECIFIC_DATE_RANGE: 'CHOOSE_SPECIFIC_DATE_RANGE'
};

export const DEFAULT_DATE_RANGE_VALUE = FILTER_OPTIONS_EXAM_DATE_RANGE.lastThreeMonths;

export const dateRangeOptions: DateRangeOption[] = [
  {
    value: FILTER_OPTIONS_EXAM_DATE_RANGE.lastMonth,
    name: 'Last month'
  },
  {
    value: FILTER_OPTIONS_EXAM_DATE_RANGE.lastThreeMonths,
    name: 'Last 3 months'
  },
  {
    value: FILTER_OPTIONS_EXAM_DATE_RANGE.lastSixMonths,
    name: 'Last 6 months'
  },
  {
    value: FILTER_OPTIONS_EXAM_DATE_RANGE.lastYear,
    name: 'Last 12 months'
  },
  {
    value: FILTER_OPTIONS_EXAM_DATE_RANGE.chooseSpecificDateRange,
    name: 'Choose Specific Date Range',
    dateRangeEnabled: true
  }
];

export const DEFAULT_DATE_RANGE_OPTION = dateRangeOptions[1];

export const HESI_PRODUCT_EXAM_TYPE = {
  specialty: 'Specialty',
  exit: 'Exit',
  cat: 'CAT'
};

export const HESI_SCORE_METHOD = {
  percentScore: 'PercentScore'
};

export const DEFAULT_ACCEPTABLE_SCORE = 850;

export const NGN_ITEM_NOT_APPLICABLE_VALUE = -1;

export const HESI_NG_LINK_KEYS = {
  HOME: 'HOME',
  EXAMS: 'EXAMS',
  REMEDIATION: 'REMEDIATION',
  EXAM_RESULTS: 'EXAM_RESULTS'
} as const;

export const HESI_NG_TARGET_PAGES = {
  HESI_INSTRUCTOR_HOME: 'HESI_INSTRUCTOR_HOME',
  HESI_REMEDIATION: 'HESI_REMEDIATION',
  HESI_EXAM_SCHEDULING: 'HESI_EXAM_SCHEDULING',
  HESI_STUDENT_HOME: 'HESI_STUDENT_HOME',
  HESI_STUDENT_REMEDIATION: 'HESI_STUDENT_REMEDIATION'
} as const;

export const MIN_CAT_SCORE_ON_CHART = 0;

export const MAX_CAT_SCORE_ON_CHART = 30;

export const DEFAULT_PERFORMANCE_LEVEL_SCORE_RANGE = {
  atRisk: { max: 0 },
  belowAcceptable: { min: 0, max: 0 },
  acceptable: { min: 0, max: 0 },
  recommended: { min: 0 }
};

const ngnDetailsHash = '#ngnDetails';

export const HESI_EXAM_RESULTS_TABS = {
  STUDENT_PERFORMANCE_TAB: {
    index: 0,
    hash: '#studentPerformance'
  },
  CATEGORY_AND_ITEMS_RESULTS_CATEGORY_PERFORMANCE_TAB: {
    index: 1,
    hash: '#categoryAndItemsResults-categoryPerformance'
  },
  CATEGORY_AND_ITEMS_RESULTS_ITEM_DETAILS_TAB: {
    index: 1,
    hash: '#categoryAndItemsResults-itemDetails'
  },
  NGN_DETAIL: {
    index: 2,
    hash: ngnDetailsHash
  }
} as const;

export const HESI_A2_EXAM_RESULTS_TABS = {
  STUDENT_PERFORMANCE_TAB: {
    index: 0,
    hash: '#studentPerformance'
  },
  SUBJECT_AREA_RESULTS_TAB: {
    index: 1,
    hash: '#subjectAreaResults'
  }
} as const;

export const HESI_EXAM_RESULTS_CATEGORY_AND_ITEMS_RESULTS_TABS = {
  CATEGORY_AND_ITEMS_RESULTS_CATEGORY_PERFORMANCE_TAB: {
    index: 0,
    hash: HESI_EXAM_RESULTS_TABS.CATEGORY_AND_ITEMS_RESULTS_CATEGORY_PERFORMANCE_TAB.hash
  },
  CATEGORY_AND_ITEMS_RESULTS_ITEM_DETAILS_TAB: {
    index: 1,
    hash: HESI_EXAM_RESULTS_TABS.CATEGORY_AND_ITEMS_RESULTS_ITEM_DETAILS_TAB.hash
  }
} as const;

export const HESI_EXAM_RESULTS_STUDENT_DETAILS_TABS = {
  CATEGORY_RESULTS_TAB: {
    index: 0,
    hash: '#categoryResults'
  },
  NGN_DETAIL: {
    index: 1,
    hash: ngnDetailsHash
  }
} as const;

export const HESI_STUDENT_EXAM_RESULTS_TABS = {
  CATEGORY_RESULTS_TAB: {
    index: 0,
    hash: '#categoryResults'
  },
  NGN_DETAIL: {
    index: 1,
    hash: ngnDetailsHash
  }
} as const;

export const EXAM_GROUP_ASSESSMENT_TYPES = {
  EXIT: 'Exit',
  CAT: 'CAT',
  SPECIALTY: 'Specialty',
  CUSTOM: 'Custom',
  SUB_SPECIALTY: 'Sub-Specialty',
  STATE_BASED: 'State-Based'
} as const;

export const ALL_TAXONOMIES_KEY = '-1';

export const HER_TAXONOMY = {
  CLIENT_NEEDS: '11',
  SUB_SPECIALTY: '88',
  NURSING_CONCEPTS: '164'
} as const;

export const HER_TAXONOMY_NAME = {
  CLIENT_NEEDS: 'Client Needs',
  SUB_SPECIALTY: 'Sub-Specialty',
  NURSING_CONCEPTS: 'Nursing Concepts'
} as const;

export const TABLE_SELECTORS = {
  CATEGORY_PERFORMANCE: {
    categoryScoreColumn: 'c-hesi-ng-category-performance-table__category-score-column'
  },
  STUDENT_DETAILS: {
    categoryScoreColumn: 'c-hesi-ng-student-category-results-table__col-category-score'
  }
} as const;

export const INTERACTION_BETWEEN_CARD_AND_TABLE = {
  [HESI_EXAM_RESULTS_TABS.CATEGORY_AND_ITEMS_RESULTS_CATEGORY_PERFORMANCE_TAB.hash]: {
    tableColumn: {
      headerSelector: TABLE_SELECTORS.CATEGORY_PERFORMANCE.categoryScoreColumn
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [HESI_EXAM_RESULTS_STUDENT_DETAILS_TABS.CATEGORY_RESULTS_TAB.hash]: {
    tableColumn: {
      headerSelector: TABLE_SELECTORS.STUDENT_DETAILS.categoryScoreColumn
    },
    sortDirection: SORT_DIRECTION.ASC
  }
} as const;

export const DEFAULT_STUDENT_DROPDOWN_OPTION = {
  recordId: '',
  eolsId: 0,
  examName: '',
  fullName: '',
  meanHesiScore: 0,
  nationalHesiAverage: 0,
  conversionScore: 0,
  ngnPercentCorrect: 0,
  dateTaken: '',
  performanceLevel: '',
  examDate: 0,
  name: '',
  key: ''
};

export const DEFAULT_HESI_NG_EXAM_RESULT_DATE_FILTER = {
  selectedDate: DEFAULT_DATE_RANGE_VALUE,
  fromDate: 0,
  toDate: 0
};

export const DEFAULT_HESI_NG_EXAM_RESULT_FILTER = {
  examType: null,
  examGroups: []
};

const today = moment().valueOf();

export const DEFAULT_HESI_NG_DATE_FORMAT = 'MM/dd/yy';
export const DATE_TAKEN_FORMAT = 'MM/DD/YY';
export const DEFAULT_DATE_RANGE = { fromDate: '', toDate: '' };

export const DEFAULT_DATE_RANGE_UNIX = { fromDateUnix: today, toDateUnix: today };

export const DISPLAY_CUSTOM_FILTER_DATE_FORMAT = 'MM/DD/YYYY';

export const DEFAULT_SUMMARY_SELECTED_EXAM = { value: '', name: '' };

export const DISPLAY_DATE_FORMAT_IN_STUDENT_DETAIL = 'DD MMM YYYY';

export const SHOW_EXAM_RESULTS_STATUS = {
  EXAM_GROUP_COMPLETE: 'EXAM_GROUP_COMPLETE',
  STUDENT_EXAM_COMPLETE: 'STUDENT_EXAM_COMPLETE',
  NEVER: 'NEVER'
};

export const SCHEDULED_EXAM_STATUS = {
  COMPLETED: 'complete'
};

export const STUDENT_EXAM_STATUS = {
  SCORING_PENDING: 'SCORING_PENDING',
  SCORING_COMPLETE: 'SCORING_COMPLETE'
};

export const HP_EXAM_KEY = 'HP';
export const A2_EXAM_KEY = 'Admissions';
export const RN_CRITICAL_THINKING_EXAM_KEY = 'RN Critical Thinking';
export const PN_CRITICAL_THINKING_EXAM_KEY = 'PN Critical Thinking';

export const FUNDAMENTALS_CONCEPTS = 'Fundamentals';
export const FUNDAMENTALS_SPECIALTY_AREA = 'Specialty Area';
