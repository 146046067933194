import React, { ReactNode } from 'react';

interface PageWrapperProps {
  children: ReactNode;
}

const PageWrapper = ({ children }: PageWrapperProps) => {
  return <div className="c-page-wrapper">{children}</div>;
};

export default PageWrapper;
