import { ROUTE_ROLES } from 'constants/app.constant';
import { lazy } from 'react';
import { ELSCommonUIConstants } from 'components/common';
import { analyticBaseConfig } from 'config/app.config';
import { SH_PATHS } from 'reports/sh/constants/sh.constant';

const { INSTRUCTOR, STUDENT } = ROUTE_ROLES;

export const ShRoutes = {
  getRoutes: () => [
    {
      path: SH_PATHS.courseAggregate,
      component: lazy(() => import(/* webpackChunkName: "SHCourseAggregatePage" */ 'reports/sh/components/pages/sh-course-aggregate/SHCourseAggregatePage')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:sh-course-aggregate`,
          pageProductName: 'sh'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: SH_PATHS.studentAssignmentView,
      component: lazy(() => import(/* webpackChunkName: "SHStudentAssignmentView" */ 'reports/sh/components/pages/sh-student-assignment-view/SHStudentAssignmentView')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:sh-student-assignment-view`,
          pageProductName: 'sh'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: SH_PATHS.studentProfile,
      component: lazy(() => import(/* webpackChunkName: "SHStudentProfile" */ 'reports/sh/components/pages/sh-student-profile/SHStudentProfile')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:sh-student-profile`,
          pageProductName: 'sh'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: SH_PATHS.dashboard,
      component: lazy(() => import(/* webpackChunkName: "SHDashboard" */ 'reports/sh/components/pages/sh-dashboard/SHDashboard')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:sh-dashboard`,
          pageProductName: 'sh'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    },
    {
      path: SH_PATHS.cpraStudentResults,
      component: lazy(() => import(/* webpackChunkName: "SHCPRAStudentResults" */ 'reports/sh/components/pages/sh-cpra/sh-cpra-student-results/SHCPRAStudentResults')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:sh-cpra-student-results`,
          pageProductName: 'sh'
        }
      },
      isPrivate: true,
      roles: STUDENT
    },
    {
      path: SH_PATHS.cpra,
      component: lazy(() => import(/* webpackChunkName: "SHCPRA" */ 'reports/sh/components/pages/sh-cpra/SHCPRA')),
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.performanceStats,
          pageName: `${analyticBaseConfig.edApp}:sh-cpra`,
          pageProductName: 'sh'
        }
      },
      isPrivate: true,
      roles: INSTRUCTOR
    }
  ]
};
