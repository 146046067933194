import React, { ReactElement } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { partition, orderBy, isArray, isNumber } from 'lodash';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ELSIcon } from 'components/common';
import { SORT_DIRECTION_ENUM, FeatureFlagNames, CS_EVOLVE_PRODUCT_TYPE_KEY } from 'constants/app.constant';
import { MenuOption, ColumnProps } from 'models';
import { isFeatureEnabled } from 'services/feature-flag.service';
import {
  ASSIGNMENT_TYPE_GROUPS,
  ASSIGNMENT_TYPES,
  EMPOWER_ICON,
  NAClassname,
  NEW_CW_DASHBOARD_ASSIGNMENT_SORT_OPTIONS,
  REPORT_DEPENDENT_OPTIONS,
  VALUE_NA,
  VALUE_NA_TEXT,
  PLAY_VIDEO,
  DATE_FORMATS,
  INSERT_QUESTION
} from '../constants/report.constant';
import { ASSIGNMENT_GRADE_TYPES } from '../constants/cw.constant';
import { AssignmentSummaryMetric, AssignmentType, CourseAggregateDTO, RenderWeightedGradePointsProps, CourseSection } from '../models';

require('assets/common/images/confirmation-outline-circle.svg');
require('assets/common/images/failed-outline-circle.svg');
require('assets/common/images/empowering-knowledge-b.svg');
require('assets/common/images/play-video.svg');
require('assets/common/images/insert-question.svg');

export const renderNACell = (row, column): ReactElement => {
  const cellValue = row[column.field];
  return <div className={cellValue === VALUE_NA ? NAClassname : ''}>{cellValue}</div>;
};

export const renderAvgScoreCell = (row, column): ReactElement => {
  const cellValue = row[column.field];
  return <div className={cellValue === VALUE_NA ? NAClassname : ''}>{cellValue}</div>;
};

export const renderNameCell = (row): ReactElement => (
  <div className="u-els-ellipsis" title={row.name}>
    <Link to={`/assignmentDetail/${row.id}`}>{row.name}</Link>
  </div>
);

export const renderType = (row): ReactElement => (
  <div>
    <FormattedMessage id={row.type} />
  </div>
);

export const renderDueDate = (row): ReactElement => <div title={row.dueDateTimestampFormat}>{row.dueDate}</div>;

export const renderPastDueCell = (row): ReactElement => <div className={row.countOfPastDue > 0 ? 'u-els-font-family-bold u-els-color-warn' : ''}>{row.countOfPastDue}</div>;

export const renderAvgCorrectAnswers = (row): ReactElement => <div>{row.avgCorrectAnswers > 0 ? row.avgCorrectAnswers : VALUE_NA}</div>;

export const renderAvgIncorrectAnswers = (row): ReactElement => <div>{row.avgIncorrectAnswers > 0 ? row.avgIncorrectAnswers : VALUE_NA}</div>;

export const renderPercentCompleted = (row): ReactElement => <div>{`${row.completedPercent}%`}</div>;

export const renderPassScore = (customClassName?: string): ReactElement => {
  return (
    <div className="c-cw-pass-fail-score">
      <svg className="c-cw-pass-fail-score__image">
        <use xlinkHref="#confirmation-outline-circle" />
      </svg>
      <strong className={classNames('u-els-color-confirm u-els-margin-left-1o2 c-cw-pass-fail-score__label', { [customClassName]: customClassName })}>Pass</strong>
    </div>
  );
};

export const renderFailScore = (customClassName?: string): ReactElement => {
  return (
    <div className="c-cw-pass-fail-score">
      <svg className="c-cw-pass-fail-score__image">
        <use xlinkHref="#failed-outline-circle" />
      </svg>
      <strong className={classNames('u-els-color-warn u-els-margin-left-1o2 c-cw-pass-fail-score__label', { [customClassName]: customClassName })}>Fail</strong>
    </div>
  );
};

export const checkShowAssignmentDetail = (row): boolean => {
  return row.countOfInProgress > 0 || row.countOfCompleted > 0 || row.countOfPastDue > 0;
};

export const formatTimeSpentToMinutes = (row): string => {
  const min = moment.duration(row.timeSpent).asMinutes();
  return min >= 1 ? `${Math.ceil(min)} min` : '-';
};

export const formatTimeSpentToMinutesAndSeconds = (row): string => {
  const duration = moment.duration(row.timeSpent);
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (minutes === 0 && seconds === 0) {
    return '-';
  }
  if (minutes === 0) {
    return `${seconds}secs`;
  }
  return `${minutes}mins ${seconds}secs`;
};

export const convertQuizTypeToType = (quizType: string): AssignmentType => {
  return ASSIGNMENT_TYPES[quizType];
};
export const getAssignmentTypeIcon = (quizType: string): ReactElement => {
  const { icon = '', group = '' } = convertQuizTypeToType(quizType) || {};
  let elsIconStyles = 'o-els-icon-svg o-els-icon-svg--font-offset o-els-icon-svg--middle';
  const customStyles = 'u-els-margin-right-1o4 o-els-icon-svg--1x1o2';

  if (icon === EMPOWER_ICON) {
    elsIconStyles = classNames(customStyles, elsIconStyles);
    return (
      <svg className={`${elsIconStyles}`}>
        <use xlinkHref="#empowering-knowledge-b" />
      </svg>
    );
  }

  if (icon === PLAY_VIDEO) {
    elsIconStyles = classNames(customStyles, elsIconStyles);
    return (
      <svg className={classNames('u-els-margin-right-3o4', elsIconStyles)}>
        <use xlinkHref="#play-video" />
      </svg>
    );
  }

  if (icon === INSERT_QUESTION) {
    elsIconStyles = classNames(customStyles, elsIconStyles);
    return (
      <svg className={classNames(elsIconStyles)}>
        <use xlinkHref="#insert-question" />
      </svg>
    );
  }

  if (group === ASSIGNMENT_TYPE_GROUPS.EAQ) {
    elsIconStyles = classNames(customStyles, elsIconStyles);
    return (
      <svg className={`${elsIconStyles}`}>
        <use xlinkHref="#eaq" />
      </svg>
    );
  }
  elsIconStyles = classNames('u-els-margin-right-3o4', elsIconStyles);
  return <ELSIcon name={icon} customClass={elsIconStyles} size="1x1o2" />;
};

export const getHiddenDependentOption = (excludeOptions: string[], assignmentTypeMenuOptions: MenuOption[]): string[] => {
  let hideOptions = [];
  excludeOptions.forEach(excludeOption => {
    const dependentOption = REPORT_DEPENDENT_OPTIONS?.find(option => option.dependentOptionlist.includes(excludeOption));
    const hasParentDependentOption = assignmentTypeMenuOptions.findIndex(option => option.key === dependentOption?.parentOption) > -1;
    if (hasParentDependentOption) {
      hideOptions = [...hideOptions, excludeOption];
    }
  });
  return hideOptions;
};

export const roundAccurately = (value: number, decimalPlaces: number): number => {
  const multipliedValue = `${value}e${decimalPlaces}`;
  return Number(`${Math.round(Number(multipliedValue))}e-${decimalPlaces}`);
};

export const renderPercentValue = (val: number, decimalPlaces = 0): string => {
  const value = val > 1 ? val : val * 100;
  return `${roundAccurately(value, decimalPlaces)}%`;
};

export const renderAvgScorePercent = (assignmentGradeType: string, avgScore: number): string => {
  let avgScoreValue;
  const percentValue = renderPercentValue(avgScore);
  switch (assignmentGradeType) {
    case ASSIGNMENT_GRADE_TYPES.NOT_GRADED.key:
      return ASSIGNMENT_GRADE_TYPES.NOT_GRADED.label;
    case ASSIGNMENT_GRADE_TYPES.PASS_FAIL.key:
      avgScoreValue = `${percentValue} Passed`;
      break;
    case ASSIGNMENT_GRADE_TYPES.SCORED.key:
      avgScoreValue = percentValue;
      break;
    default:
      break;
  }
  return isNumber(avgScore) ? avgScoreValue : VALUE_NA_TEXT;
};

export const renderWeightedGradePoints = ({
  assignmentGradeType,
  points,
  totalPoints,
  featureFlags,
  pastDue,
  courseId = '',
  withParenthesis = true
}: RenderWeightedGradePointsProps): string => {
  const isGradePointEnable = isFeatureEnabled(featureFlags, FeatureFlagNames.CW_GRADE_WEIGHTING_SCORE_ENABLED, courseId);
  const weightedGradePoints = `${!pastDue ? Math.round(points * totalPoints * 100 + Number.EPSILON) / 100 : 0}/${Math.round(totalPoints) || 0} points`;
  if (assignmentGradeType !== ASSIGNMENT_GRADE_TYPES.NOT_GRADED.key && isGradePointEnable) {
    return `${withParenthesis ? '(' : ''}${weightedGradePoints}${withParenthesis ? ')' : ''}`;
  }
  return '';
};

export const renderWeightedGradePointsInGradebook = ({
  assignmentGradeType,
  points,
  totalPoints,
  featureFlags,
  courseId = '',
  withParenthesis = true
}: RenderWeightedGradePointsProps): string => {
  const isGradePointEnable = isFeatureEnabled(featureFlags, FeatureFlagNames.CW_GRADE_WEIGHTING_SCORE_ENABLED_IN_GRADEBOOK, courseId);
  const numOfPoints = Math.round(points * totalPoints * 100 + Number.EPSILON) / 100;
  const weightedGradePoints = `${!isNaN(numOfPoints) ? numOfPoints : 0}/${Math.round(totalPoints) || 0} points`;
  if (assignmentGradeType !== ASSIGNMENT_GRADE_TYPES.NOT_GRADED.key && isGradePointEnable) {
    return `${withParenthesis ? '(' : ''}${weightedGradePoints}${withParenthesis ? ')' : ''}`;
  }
  return '';
};

export const getPointFromWeightedGradePoints = (gradePoints: string): { point: number; gradePoint: number } => {
  const gradePointsValue = gradePoints.split('/', 2);
  const regex = new RegExp('([0-9]*[.])?[0-9]+', 'g');
  const point = +gradePointsValue[0].match(regex)[0];
  const gradePoint = +gradePointsValue[1].match(regex)[0];
  return { point, gradePoint };
};

export const sortSummaryAssignments = (assignments: Array<AssignmentSummaryMetric>, sortDirection: string, sortOption: string): Array<AssignmentSummaryMetric> => {
  const direction = sortDirection === 'asc' ? 1 : -1;
  const assignmentsDueNext7Day = assignments.filter(
    assignment =>
      assignment.dueDate !== null &&
      moment(assignment.dueDate).isBetween(
        moment().startOf('day'),
        moment()
          .startOf('day')
          .add(7, 'days')
      )
  );
  let sortedGradeAssignments;
  switch (sortOption) {
    case NEW_CW_DASHBOARD_ASSIGNMENT_SORT_OPTIONS[0].value: {
      // sort by due date
      return [...assignmentsDueNext7Day].sort((a, b) => direction * moment(a.dueDate).diff(moment(b.dueDate)));
    }
    case NEW_CW_DASHBOARD_ASSIGNMENT_SORT_OPTIONS[2].value: {
      // sort by class avg score
      const [notGradedAssignments, gradedAssignments] = partition(assignmentsDueNext7Day, ['assignmentGradeType', 'NOT_GRADED']);
      const sortedNotGradedAssignments = [...notGradedAssignments].sort((a, b) => (a.assignmentName > b.assignmentName ? 1 : -1));
      sortedGradeAssignments = [...gradedAssignments].sort((a, b) => {
        if (a.avgScore === b.avgScore) {
          return a.assignmentName > b.assignmentName ? 1 : -1;
        }
        return direction * (a[sortOption] - b[sortOption]);
      });

      return direction > 0 ? [...sortedNotGradedAssignments, ...sortedGradeAssignments] : [...sortedGradeAssignments, ...sortedNotGradedAssignments];
    }
    case NEW_CW_DASHBOARD_ASSIGNMENT_SORT_OPTIONS[3].value: {
      // sort by average time spent
      return [...assignmentsDueNext7Day].sort((a, b) => direction * (moment.duration(a[sortOption]).asSeconds() - moment.duration(b[sortOption]).asSeconds()));
    }
    default: {
      return [...assignmentsDueNext7Day].sort((a, b) => direction * (a[sortOption] - b[sortOption]));
    }
  }
};

export const renderColumn = (column: ColumnProps, defaultHandleHeaderClick?: Function): ReactElement => {
  const { field, header, sticky, align, customRender, customSort, handleHeaderClick } = column;
  const columnProps = {
    field,
    header,
    sticky,
    sortable: true,
    align,
    customSort,
    customRender,
    handleHeaderClick: handleHeaderClick || defaultHandleHeaderClick
  };
  return <column key={field} {...columnProps} />;
};

export const getValidAssignments = <T extends unknown>(assignments: Array<T>): Array<T> => {
  const field = 'assignmentType';
  return assignments.filter(asm => ASSIGNMENT_TYPES[asm[field]]);
};

export const getSortedCourseAggregate = <T extends unknown>(courseAggregateData: T[], direction: SORT_DIRECTION_ENUM, value: string): T[] => {
  return orderBy(courseAggregateData, value, direction) as T[];
};

export const isNotGradeAssignment = (gradeType: string): boolean => {
  return gradeType === ASSIGNMENT_GRADE_TYPES.NOT_GRADED.key;
};

export const isEABAssignment = (assignmentType: string | string[]): boolean => {
  const assignmentEABTypeId = ASSIGNMENT_TYPES.PERSONAL_ASSESSMENT_BUILDER.id;

  if (isArray(assignmentType)) {
    return assignmentType.includes(assignmentEABTypeId);
  }
  return assignmentType === assignmentEABTypeId;
};

export const getIsOsmosisAssignment = (assignmentType: string | string[]): boolean => {
  const osmosisTypeId = ASSIGNMENT_TYPES.OSMOSIS_VIDEO.id;
  if (isArray(assignmentType)) {
    return assignmentType.every(at => at === osmosisTypeId);
  }
  return assignmentType === osmosisTypeId;
};

export const remapCourseAggregate = (courseAggregate: CourseAggregateDTO, courseSection: CourseSection): CourseAggregateDTO => {
  const { activeCourseWithRecentActivity, createdAt, updatedAt, courseName, entitlements = [] } = courseSection;
  let courseCreationDate = updatedAt || createdAt;
  const isCSCourse = entitlements.some(({ evolveProductTypeKey }) => evolveProductTypeKey === CS_EVOLVE_PRODUCT_TYPE_KEY);
  if (isNumber(courseCreationDate)) {
    courseCreationDate = moment(courseCreationDate).format(DATE_FORMATS.CUSTOM);
  }
  return {
    ...courseAggregate,
    courseActive: activeCourseWithRecentActivity,
    courseCreationDate,
    courseName,
    isCSCourse
  };
};

export const remapCourseSection = (courseSection: CourseSection): CourseSection => {
  const { activeCourseWithRecentActivity, createdAt, updatedAt, courseName } = courseSection;
  let courseCreationDate = updatedAt || createdAt;
  if (isNumber(courseCreationDate)) {
    courseCreationDate = moment(courseCreationDate).format(DATE_FORMATS.CUSTOM);
  }
  return {
    ...courseSection,
    courseActive: activeCourseWithRecentActivity,
    courseCreationDate,
    courseName
  };
};

export const naNtoZero = (value: number | string): number => {
  if (typeof value === 'string') {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
  }
  return isNaN(value) ? 0 : value;
};
