import { createAction, handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import { AppConstant } from 'constants/app.constant';
import { ExamData, ExamTypeOption, HesiNGStudentSummaryResult } from 'reports/hesi-ng/models';
import { getExamGroupIdByAllowPartialCreditFlag, mapNGNItemHesiNGToNGNItem } from 'reports/hesi-ng/helpers';

const defaultExamData: ExamData = {
  accountInfo: [],
  caseStudyVtwIdInfo: [],
  examGroupInfo: [],
  examInfo: [],
  examQuestionInfo: [],
  studyPacketInfo: [],
  testTakerData: []
};

const defaultExamSummary = {
  meanScore: 0,
  studentBelowAcceptableScore: 0,
  numberTestTaker: 0,
  examName: null,
  acceptableScore: 0,
  compareHesiToNational: 0,
  productName: null,
  showNationalScore: false,
  showBelowAcceptableScore: false,
  showMeanScoreChart: false,
  isExamTypeCat: false,
  recommendedScore: 0
};

export const defaultExamType: ExamTypeOption = {
  value: '',
  name: '',
  productName: '',
  examGroupAssessmentType: '',
  acceptableScore: 0,
  editionId: 0,
  examName: '',
  nationalHesiAverage: 0,
  productId: 0,
  recommendedScore: 0,
  scoreHighRange: 0,
  scoreMethodId: 0,
  scoreMethodName: ''
};

export const defaultStudentExamSummaryResult = {
  summary: {
    overall: {
      scores: {
        current: {
          hesi: 0,
          conversion: 0
        },
        range: {
          acceptable: 0,
          nationalAverage: 0,
          recommended: 0
        }
      },
      examDate: 0,
      questionsCount: 0,
      questionsSeen: 0,
      questionsCompleted: 0,
      questionsCorrect: 0
    },
    concepts: []
  },
  students: []
} as HesiNGStudentSummaryResult;

const defaultSelectedStudentExam = {
  key: '',
  name: '',
  examId: null,
  examName: '',
  examType: '',
  examDate: null,
  examGroupId: null,
  examVersion: null,
  guid: null,
  categoryDisplayName: null
};

export const initialState = Immutable({
  ngnItems: [],
  examData: defaultExamData,
  examGroupIdSelected: [],
  studentListPerformance: [],
  isFetchingStudentsListPerformance: false,
  performanceLevelStudentFilter: null,
  examResultSummary: defaultExamSummary,
  selectedExamName: '',
  selectedStudent: null,
  selectedExamType: defaultExamType,
  selectedTaxonomyName: '',
  selectedStudentTaxonomyName: '',
  selectedStudentRoleTaxonomyName: null,
  weakestCategoriesAndTableSortInteraction: null,
  studentWeakestCategoriesAndTableSortInteraction: null,
  studentExamSummaryResult: defaultStudentExamSummaryResult,
  isStudentExamSummaryResultLoading: false,
  isFilterExamModalOpen: false,
  examGroupOptions: [],
  allExamGroupOptions: [],
  examTypeOptions: [],
  studentExamGroupInfo: null,
  selectedStudentExam: defaultSelectedStudentExam,
  orderItemSummaryData: { conceptData: [], testTakerData: [] },
  externalCourseSectionId: null,
  orderItemSummaryMessage: '',
  isWeakestSubjectAreaCardAndTableSortInteraction: false
});

const prefixActionName = 'HESI_NG';

const restoreState = createAction(`${prefixActionName}/RESTORE`);
const setNGNItem = createAction(`${prefixActionName}/SET_NGN_ITEM`);
const setExamData = createAction(`${prefixActionName}/SET_EXAM_DATA`);
const setExamGroupInfo = createAction(`${prefixActionName}/SET_EXAM_GROUP_INFO`);
const setExamGroupIdSelected = createAction(`${prefixActionName}/SET_EXAM_GROUP_ID_SELECTED`);
const setStudentListPerformance = createAction(`${prefixActionName}/SET_STUDENTS_LIST_PERFORMANCE`);
const setIsFetchingStudentsListPerformance = createAction(`${prefixActionName}/SET_IS_FETCHING_STUDENTS_LIST_PERFORMANCE`);
const setPerformanceLevelStudentFilter = createAction(`${prefixActionName}/SET_PERFORMANCE_LEVEL_STUDENT_FILTER`);
const setExamResultSummary = createAction(`${prefixActionName}/SET_EXAM_RESULT_SUMMARY`);
const setSelectedExamName = createAction(`${prefixActionName}/SET_SELECTED_EXAM_NAME`);
const setSelectedStudent = createAction(`${prefixActionName}/SET_SELECTED_STUDENT`);
const setSelectedExamType = createAction(`${prefixActionName}/SET_SELECTED_EXAM_TYPE`);
const setSelectedTaxonomyName = createAction(`${prefixActionName}/SET_SELECTED_TAXONOMY_NAME`);
const setSelectedStudentTaxonomyName = createAction(`${prefixActionName}/SET_SELECTED_STUDENT_TAXONOMY_NAME`);
const setSelectedStudentRoleTaxonomyName = createAction(`${prefixActionName}/SET_SELECTED_STUDENT_ROLE_TAXONOMY_NAME`);
const setWeakestCategoriesAndTableSortInteraction = createAction(`${prefixActionName}/SET_WEAKEST_CATEGORIES_AND_TABLE_SORT_INTERACTION`);
const setStudentWeakestCategoriesAndTableSortInteraction = createAction(`${prefixActionName}/SET_STUDENT_WEAKEST_CATEGORIES_AND_TABLE_SORT_INTERACTION`);
const setStudentExamSummaryResult = createAction(`${prefixActionName}/SET_STUDENT_EXAM_SUMMARY_RESULT`);
const setIsStudentExamSummaryResultLoading = createAction(`${prefixActionName}/SET_IS_STUDENT_EXAM_SUMMARY_RESULT_LOADING`);
const setIsFilterExamModalOpen = createAction(`${prefixActionName}/SET_IS_FILTER_EXAM_MODAL_OPEN`);
const setStudentExamGroupInfo = createAction(`${prefixActionName}/SET_STUDENT_EXAM_GROUP_INFO`);
const setExamGroupOptions = createAction(`${prefixActionName}/SET_EXAM_GROUP_OPTIONS`);
const setAllExamGroupOptions = createAction(`${prefixActionName}/SET_ALL_EXAM_GROUP_OPTIONS`);
const setExamTypeOptions = createAction(`${prefixActionName}/SET_EXAM_TYPE_OPTIONS`);
const setSelectedStudentExam = createAction(`${prefixActionName}/SET_SELECTED_STUDENT_EXAM`);
const setOrderItemSummaryData = createAction(`${prefixActionName}/SET_ORDER_ITEM_SUMMARY_DATA`);
const setExternalCourseSectionId = createAction(`${prefixActionName}/SET_EXTERNAL_COURSE_SECTION_ID`);
const setOrderItemSummaryMessage = createAction(`${prefixActionName}/SET_ORDER_ITEM_SUMMARY_MESSAGE`);
const setIsWeakestSubjectAreaCardAndTableSortInteraction = createAction(`${prefixActionName}/SET_IS_WEAKEST_SUBJECT_AREA_CARD_AND_TABLE_SORT_INTERACTION`);

const actions = {
  restoreState,
  setNGNItem,
  setExamData,
  setExamGroupInfo,
  setExamGroupIdSelected,
  setStudentListPerformance,
  setIsFetchingStudentsListPerformance,
  setPerformanceLevelStudentFilter,
  setExamResultSummary,
  setSelectedExamName,
  setSelectedStudent,
  setSelectedExamType,
  setSelectedTaxonomyName,
  setWeakestCategoriesAndTableSortInteraction,
  setSelectedStudentTaxonomyName,
  setStudentWeakestCategoriesAndTableSortInteraction,
  setStudentExamSummaryResult,
  setSelectedStudentRoleTaxonomyName,
  setIsStudentExamSummaryResultLoading,
  setIsFilterExamModalOpen,
  setExamGroupOptions,
  setAllExamGroupOptions,
  setExamTypeOptions,
  setStudentExamGroupInfo,
  setSelectedStudentExam,
  setOrderItemSummaryData,
  setExternalCourseSectionId,
  setOrderItemSummaryMessage,
  setIsWeakestSubjectAreaCardAndTableSortInteraction
};

const reducer = handleActions(
  {
    [actions.restoreState]: (state, { payload }) => state.merge(payload),
    [actions.setNGNItem]: (state, { payload }) => Immutable.set(state, 'ngnItems', payload),
    [actions.setExamData]: (state, { payload }) => Immutable.set(state, 'examData', payload),
    [actions.setExamGroupInfo]: (state, { payload }) => Immutable.set(state, 'examData', { ...state?.examData, examGroupInfo: payload }),
    [actions.setExamGroupIdSelected]: (state, { payload }) => Immutable.set(state, 'examGroupIdSelected', payload),
    [actions.setStudentListPerformance]: (state, { payload }) => Immutable.set(state, 'studentListPerformance', payload),
    [actions.setIsFetchingStudentsListPerformance]: (state, { payload }) => Immutable.set(state, 'isFetchingStudentsListPerformance', payload),
    [actions.setPerformanceLevelStudentFilter]: (state, { payload }) => Immutable.set(state, 'performanceLevelStudentFilter', payload),
    [actions.setExamResultSummary]: (state, { payload }) => Immutable.set(state, 'examResultSummary', payload),
    [actions.setSelectedExamName]: (state, { payload }) => Immutable.set(state, 'selectedExamName', payload),
    [actions.setSelectedStudent]: (state, { payload }) => Immutable.set(state, 'selectedStudent', payload),
    [actions.setSelectedExamType]: (state, { payload }) => Immutable.set(state, 'selectedExamType', payload),
    [actions.setSelectedTaxonomyName]: (state, { payload }) => Immutable.set(state, 'selectedTaxonomyName', payload),
    [actions.setSelectedStudentTaxonomyName]: (state, { payload }) => Immutable.set(state, 'selectedStudentTaxonomyName', payload),
    [actions.setSelectedStudentRoleTaxonomyName]: (state, { payload }) => Immutable.set(state, 'selectedStudentRoleTaxonomyName', payload),
    [actions.setWeakestCategoriesAndTableSortInteraction]: (state, { payload }) => Immutable.set(state, 'weakestCategoriesAndTableSortInteraction', payload),
    [actions.setStudentWeakestCategoriesAndTableSortInteraction]: (state, { payload }) => Immutable.set(state, 'studentWeakestCategoriesAndTableSortInteraction', payload),
    [actions.setStudentExamSummaryResult]: (state, { payload }) => Immutable.set(state, 'studentExamSummaryResult', payload),
    [actions.setIsStudentExamSummaryResultLoading]: (state, { payload }) => Immutable.set(state, 'isStudentExamSummaryResultLoading', payload),
    [actions.setIsFilterExamModalOpen]: (state, { payload }) => Immutable.set(state, 'isFilterExamModalOpen', payload),
    [actions.setExamGroupOptions]: (state, { payload }) => Immutable.set(state, 'examGroupOptions', payload),
    [actions.setAllExamGroupOptions]: (state, { payload }) => Immutable.set(state, 'allExamGroupOptions', payload),
    [actions.setExamTypeOptions]: (state, { payload }) => Immutable.set(state, 'examTypeOptions', payload),
    [actions.setStudentExamGroupInfo]: (state, { payload }) => Immutable.set(state, 'studentExamGroupInfo', payload),
    [actions.setSelectedStudentExam]: (state, { payload }) => Immutable.set(state, 'selectedStudentExam', payload),
    [actions.setOrderItemSummaryData]: (state, { payload }) => Immutable.set(state, 'orderItemSummaryData', payload),
    [actions.setExternalCourseSectionId]: (state, { payload }) => Immutable.set(state, 'externalCourseSectionId', payload),
    [actions.setOrderItemSummaryMessage]: (state, { payload }) => Immutable.set(state, 'orderItemSummaryMessage', payload),
    [actions.setIsWeakestSubjectAreaCardAndTableSortInteraction]: (state, { payload }) => Immutable.set(state, 'isWeakestSubjectAreaCardAndTableSortInteraction', payload)
  },
  initialState
);

const getHesiNGState = state => state[AppConstant.reduxResources.HESI_NG_STATE];

const getRemappedNgnItems = createSelector(getHesiNGState, state => {
  const { examData, ngnItems = [] } = state;
  const { examGroupInfo } = examData;
  const examGroupIdMap = getExamGroupIdByAllowPartialCreditFlag(examGroupInfo);
  return ngnItems.map(item => mapNGNItemHesiNGToNGNItem(item, {}, examGroupIdMap));
});

const selectors = {
  getNGNItems: createSelector(getHesiNGState, state => state.ngnItems),
  getRemappedNgnItems,
  getExamData: createSelector(getHesiNGState, state => state.examData),
  getExamGroupIdSelected: createSelector(getHesiNGState, state => state.examGroupIdSelected),
  getStudentListPerformance: createSelector(getHesiNGState, state => state.studentListPerformance),
  getIsFetchingStudentsListPerformance: createSelector(getHesiNGState, state => state.isFetchingStudentsListPerformanceTab),
  getPerformanceLevelStudentFilter: createSelector(getHesiNGState, state => state.performanceLevelStudentFilter),
  getExamResultSummary: createSelector(getHesiNGState, state => state.examResultSummary),
  getSelectedExamName: createSelector(getHesiNGState, state => state.selectedExamName),
  getSelectedStudent: createSelector(getHesiNGState, state => state.selectedStudent),
  getHesiExamsResultsFilter: createSelector(getHesiNGState, state => state.hesiExamsResultsFilter),
  getSelectedExamType: createSelector(getHesiNGState, state => state.selectedExamType),
  getWeakestCategoriesAndTableSortInteraction: createSelector(getHesiNGState, state => state.weakestCategoriesAndTableSortInteraction),
  getSelectedTaxonomyName: createSelector(getHesiNGState, state => state.selectedTaxonomyName),
  getSelectedStudentTaxonomyName: createSelector(getHesiNGState, state => state.selectedStudentTaxonomyName),
  getSelectedStudentRoleTaxonomyName: createSelector(getHesiNGState, state => state.selectedStudentRoleTaxonomyName),
  getStudentWeakestCategoriesAndTableSortInteraction: createSelector(getHesiNGState, state => state.studentWeakestCategoriesAndTableSortInteraction),
  getStudentExamSummaryResult: createSelector(getHesiNGState, state => state.studentExamSummaryResult),
  getIsStudentExamSummaryResultLoading: createSelector(getHesiNGState, state => state.isStudentExamSummaryResultLoading),
  getIsFilterExamModalOpen: createSelector(getHesiNGState, state => state.isFilterExamModalOpen),
  getExamGroupOptions: createSelector(getHesiNGState, state => state.examGroupOptions),
  getAllExamGroupOptions: createSelector(getHesiNGState, state => state.allExamGroupOptions),
  getExamTypeOptions: createSelector(getHesiNGState, state => state.examTypeOptions),
  getStudentExamGroupInfo: createSelector(getHesiNGState, state => state.studentExamGroupInfo),
  getSelectedStudentExam: createSelector(getHesiNGState, state => state.selectedStudentExam),
  getOrderItemSummaryData: createSelector(getHesiNGState, state => state.orderItemSummaryData),
  getExternalCourseSectionId: createSelector(getHesiNGState, state => state.externalCourseSectionId),
  getOrderItemSummaryMessage: createSelector(getHesiNGState, state => state.orderItemSummaryMessage),
  getIsWeakestSubjectAreaCardAndTableSortInteraction: createSelector(getHesiNGState, state => state.isWeakestSubjectAreaCardAndTableSortInteraction)
};

export { actions as hesiNgActions, reducer as hesiNgReducer, selectors as hesiNgSelectors };
export { initialState as hesiNgInitialState };
