import moment from 'moment';
import { keyBy, mapValues, first, isNil } from 'lodash';
import { ELSLoggingService } from 'components/common';
import { SystemType } from 'models';
import { getStudentUserFullName, getTimeZone } from 'helpers/app.helper';
import { FULFILLED_STATUS } from 'constants/app.constant';
import { facadeApi, perFacadeApi } from 'helpers/api.helper';
import { fetchCrosswalkUser } from 'services/user-management.service';
import {
  ASSIGNMENT_GRADES_TYPES,
  DATE_FORMATS,
  eaqAssignmentConstants,
  eaqConstants,
  NO_DUE_DATE,
  VALUE_NA,
  ASSIGNMENT_TYPES,
  ASSIGNMENT_TYPE_GROUPS
} from 'reports/cw/constants/report.constant';
import {
  AssignmentDetailReportRecord,
  AssignmentEngagementRecord,
  RemappedAssignmentInfo,
  StudentEngagementRecord,
  GradebookData,
  AssignmentPerformance,
  StudentDetailData,
  StudentEngagementDTO,
  StudentEngagementMetricDTO,
  AssignmentEngagementDTO,
  AssignmentEngagementMetricDTO,
  AssignmentSummary,
  StudentHomeReportDTO,
  EngagementSummaryDTO,
  DashboardProgressingDTO,
  CourseAggregateDTO,
  CourseSection,
  CWAssignment,
  StudentHomeAssignmentReportDTO
} from 'reports/cw/models';
import { fetchAllCourseAggregations } from 'reports/ps/services/ps.service';
import { remapCourseAggregate, remapCourseSection } from '../helpers/report.helper';

const asTimestring = seconds => {
  const minutes = Math.round(seconds / 60);
  const minutesPastHour = minutes % 60;
  const hours = Math.floor(minutes / 60);
  // Format: hours, minutes past the hour, seconds past the minute
  if (hours > 0) {
    return `${hours}h ${minutesPastHour}min`;
  }
  if (minutesPastHour > 0) {
    return `${minutesPastHour}min`;
  }
  return VALUE_NA;
};

const asTimestringFormated = (seconds: number) => {
  const secondsPastMinute = seconds % 60;
  const minutes = Math.floor(seconds / 60);
  const minutesPastHour = minutes % 60;
  const hours = Math.floor(minutes / 60);
  // Format: hours, minutes past the hour, seconds past the minute
  const formattedDisplay = (number, unit) => {
    return number > 0 ? `${number}${unit}` : '';
  };
  if (hours > 0) {
    return `${formattedDisplay(hours, 'h')} ${formattedDisplay(minutesPastHour, 'min')} ${formattedDisplay(secondsPastMinute, 's')}`.trim();
  }
  if (minutesPastHour > 0) {
    return `${formattedDisplay(minutesPastHour, 'min')} ${formattedDisplay(secondsPastMinute, 's')}`.trim();
  }
  return `${secondsPastMinute}s`;
};

const durationAsTimestring = duration => {
  const seconds = Math.floor(duration.seconds());
  const minutes = duration.minutes();
  const hours = Math.floor(duration.asHours());
  // Format: hours, minutes, seconds
  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  }
  if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  }
  return `${seconds}s`;
};

const asTimestamp = (str, stamp) => {
  return moment(stamp).isValid() ? moment(stamp).format(DATE_FORMATS.TIMESTAMP) : VALUE_NA;
};

// PT-12M3S ==> -PT12M3S
const convertNegativeDuration = (duration: string): string => {
  if (duration.includes('-')) {
    return `-${duration.replace('-', '')}`;
  }
  return duration;
};

// 12 ==> "+12" && -12 => "-12"
const displayNumberWithSign = (num: number): string => {
  return num > 0 ? `+${num.toFixed(2)}` : `${num.toFixed(2)}`;
};

const getAssignmentTypeTranslateText = assignmentType => {
  switch (assignmentType) {
    case eaqConstants.assignmentType.mastery:
      return 'ASSIGNMENTS_GOAL';
    case eaqConstants.assignmentType.standard:
      return 'CUSTOM';
    case eaqConstants.assignmentType.quizByQuestion:
      return 'ASSIGNMENTS_CUSTOM_BY_QUESTION';
    default:
      return '';
  }
};

const getAvgStudentsAchievedMastery = assignmentStatus => {
  const { completed } = assignmentStatus;
  const totalStudents: number = Object.values(assignmentStatus).reduce((total: number, currentItem: number) => total + currentItem, 0) as number;
  const avgStudentsAchievedMastery = completed / totalStudents || 0;

  return {
    value: `${Math.floor(avgStudentsAchievedMastery * 100)}%`,
    sort: avgStudentsAchievedMastery
  };
};

const getGradeTypeDisplayName = assignmentGradeType => {
  switch (assignmentGradeType) {
    case eaqAssignmentConstants.labels.assignmentGrades.notGraded:
      return 'GRADE_TYPE_NOT_GRADED';
    case eaqAssignmentConstants.labels.assignmentGrades.graded:
      return 'GRADE_TYPE_PASS_FAIL';
    case eaqAssignmentConstants.labels.assignmentGrades.gradedScored:
      return 'GRADE_TYPE_SCORED';
    default:
      return '';
  }
};

const getGradeValue = grade => {
  if (grade) {
    return grade === 'PASS' ? 'Pass' : 'Fail';
  }
  return '';
};

const getAssignmentGradeBasedOnGradeTypeAndScore = (assignmentGradeType, grade, score) => {
  let value;
  let sort;
  switch (assignmentGradeType) {
    case ASSIGNMENT_GRADES_TYPES.GRADED:
      value = getGradeValue(grade);
      sort = value;
      break;
    case ASSIGNMENT_GRADES_TYPES.GRADED_SCORED:
      value = `${score || 0}%`;
      sort = score || 0;
      break;
    default:
      value = 'not graded';
      sort = value;
      break;
  }
  return { value, sort };
};

const getMasteryAssignmentGoal = masteryLevel => {
  const roundedMasteryLevel = Math.floor(masteryLevel);
  if (roundedMasteryLevel < 1) {
    return 'MASTERY_PRE_NOVICE';
  }
  if (roundedMasteryLevel >= 1 && roundedMasteryLevel < 2) {
    return 'MASTERY_NOVICE';
  }
  if (roundedMasteryLevel >= 2 && roundedMasteryLevel < 3) {
    return 'MASTERY_INTERMEDIATE';
  }
  return 'MASTERY_PROFICIENT';
};

const getMasteryGoalText = assignmentGoal => getMasteryAssignmentGoal(assignmentGoal);

const getNonMasteryAssignmentGoal = questionCount => {
  const roundedQuestionCount = Math.round(questionCount);
  return {
    questionCount: roundedQuestionCount,
    assignmentGoalText: roundedQuestionCount === 1 ? 'QUESTION' : 'QUESTIONS'
  };
};

const getAssignmentGoal = (assignmentType, assignmentGoals = []) => {
  if (assignmentType === eaqConstants.assignmentType.mastery) {
    return {
      goal: getMasteryAssignmentGoal(assignmentGoals[0].goal)
    };
  }

  const { questionCount, assignmentGoalText } = getNonMasteryAssignmentGoal(assignmentGoals[0].goal);
  return {
    questionCount,
    assignmentGoalText,
    goal: `${questionCount} ${assignmentGoalText}`
  };
};

const transformAssignmentStatus = assignmentStatus => {
  let isPastDue = false;
  let status;
  switch (assignmentStatus) {
    case 'PAST_DUE':
      isPastDue = true;
      status = 'Past due';
      break;
    case 'COMPLETED':
      status = 'Completed';
      break;
    case 'IN_PROGRESS':
      status = 'In progress';
      break;
    default:
      status = 'Not started';
  }
  return { isPastDue, status };
};

const remapFieldsForEngagementReport = (item: StudentEngagementMetricDTO, avgTimeOfClassOnQuestions: string, isEAQAssignmentType: boolean): StudentEngagementRecord => {
  const avgTimePerQuestionDuration = isEAQAssignmentType ? moment.duration(item.avgTimeSpentOnQuestions) : moment.duration(item.avgTimeSpent);
  const avgTimeSpentCompareToClassAvg = moment.duration(avgTimePerQuestionDuration).subtract(avgTimeOfClassOnQuestions);

  return {
    studentId: item.userId,
    studentName: getStudentUserFullName(item),
    percentAvgScore: +item.percentAvgScore || 0,
    performanceComparedToClassAvg: +item.avgScore || 0,
    numberOfAssignedQuestionsAttempted: item.totalAssignmentQuestionsAnswered,
    numberOfSelfStudyQuestionsAttempted: item.totalSelfStudyQuestionsAnswered,
    avgScore: item.avgScore,
    totalGradePoints: item.totalGradePoints || 0,
    totalScore: item.totalScore || 0,
    avgTimePerQuestionSeconds: avgTimePerQuestionDuration.asSeconds(),
    avgTimePerQuestionComparedToClassAvgSeconds: avgTimeSpentCompareToClassAvg.asSeconds(),
    pastDue: item.totalAssignmentsPastDue
  };
};

const remapFieldsForAssignmentEngagementTable = (assignmentEngagement: AssignmentEngagementMetricDTO): AssignmentEngagementRecord => {
  const {
    assignmentId,
    assignmentName,
    percentComplete,
    assignmentGradeType,
    avgScore,
    avgTimeSpent,
    avgTimeSpentPerQuestion,
    dueDate,
    assignmentType,
    avgQuestionsAnsweredCorrect,
    avgQuestionsAnsweredIncorrect
  } = assignmentEngagement;
  const isEAQAssignmentType = ASSIGNMENT_TYPES[assignmentType].group === ASSIGNMENT_TYPE_GROUPS.EAQ;
  const classAvgTimeSpentValue = isEAQAssignmentType ? avgTimeSpentPerQuestion : avgTimeSpent;
  const classAvgTimeSpentDuration = moment.duration(classAvgTimeSpentValue);
  const numberOfAvgQuestionAttempt = (avgQuestionsAnsweredCorrect || 0) + (avgQuestionsAnsweredIncorrect || 0);

  return {
    id: assignmentId,
    name: assignmentName,
    percentCompletion: +percentComplete || 0,
    scoringType: assignmentGradeType,
    classAvgScore: +avgScore || 0,
    numberOfAvgQuestionAttempt: +numberOfAvgQuestionAttempt.toFixed(2),
    classAvgTimeSpentSeconds: classAvgTimeSpentDuration.asSeconds(),
    dueDate,
    assignmentType
  };
};

const remapFieldsForAssignmentDetailReport = (item, assignmentGradeType): AssignmentDetailReportRecord => {
  const user = item.eolsUserDto;
  const metrics = item.questionCount;
  const timeSpent = Math.floor(item.timeSpent);
  const score = Math.floor(item.score * 100);
  const grade = getAssignmentGradeBasedOnGradeTypeAndScore(assignmentGradeType, item.grade, score);

  return {
    name: `${user.lastName}, ${user.firstName}`,
    nameSort: user.lastName.toLowerCase(),
    studentId: user.id,
    dateSubmitted: item.submitTime
      ? moment
          .utc(item.submitTime)
          .local()
          .format(DATE_FORMATS.DEFAULT)
      : VALUE_NA,
    grade: grade.value,
    gradeSort: grade.sort,
    timeSpent: asTimestring(timeSpent),
    timeSpentSort: timeSpent,
    countOfCorrectAnswers: metrics.totalCorrectAnswered,
    countOfIncorrectAnswers: metrics.totalIncorrectAnswered,
    ...transformAssignmentStatus(item.status)
  };
};

const remapFieldsForAssignmentInfo = (assignmentData): RemappedAssignmentInfo => {
  if (!assignmentData) {
    return {} as RemappedAssignmentInfo;
  }

  const { id, assignmentGoals, assignmentTopics, title, dueDate, assignmentGradeType, assignmentType } = assignmentData;

  return {
    id,
    assignmentType,
    dueDate: dueDate
      ? moment
          .utc(dueDate)
          .local()
          .format(DATE_FORMATS.LOCALE)
      : NO_DUE_DATE,
    gradeType: getGradeTypeDisplayName(assignmentGradeType),
    title,
    topics: (assignmentTopics || []).map(item => item.text).join('; '),
    type: getAssignmentTypeTranslateText(assignmentType),
    ...getAssignmentGoal(assignmentType, assignmentGoals)
  };
};

const fetchAssignmentInfo = assignmentId => {
  if (assignmentId) {
    return facadeApi.get(`/eaq/assignment/${assignmentId}`);
  }
  return Promise.resolve({});
};

const fetchAssignmentDetail = assignmentId => {
  if (assignmentId) {
    return facadeApi.get(`/eaq/assignmentDetail/${assignmentId}`);
  }
  return Promise.resolve([]);
};

const fetchStudentEngagements = (courseId: number, assignmentType?: string): Promise<{ data: StudentEngagementDTO }> => {
  if (!courseId) {
    return Promise.resolve({ data: {} as StudentEngagementDTO });
  }
  return facadeApi.get(`/performancereport/studentengagement/reportV2?courseSectionId=${courseId}&contentType=${assignmentType}`);
};

const fetchAssignmentEngagements = (courseId: number, assignmentType?: string): Promise<{ data: AssignmentEngagementDTO }> => {
  if (!courseId) {
    return Promise.resolve({ data: {} as AssignmentEngagementDTO });
  }

  const contentTypeQuery = assignmentType ? `&contentType=${assignmentType}` : '';
  return facadeApi.get(`/performancereport/assignmentEngagement/reportV2?courseSectionId=${courseId}${contentTypeQuery}`);
};

const fetchStudentDetailReport = (courseSectionId: number, studentId: string): Promise<{ data: StudentDetailData }> => {
  if (!courseSectionId || !studentId) {
    return Promise.resolve({ data: {} as StudentDetailData });
  }
  return facadeApi.get(`/gradebook/studentdetail/courseSection/${courseSectionId}/student/${studentId}`);
};

const fetchGrabookStudentDetailReport = (courseSectionId: number): Promise<{ data: StudentDetailData[] }> => {
  if (!courseSectionId) {
    return Promise.resolve({ data: [{} as StudentDetailData] });
  }
  return perFacadeApi.get(`/gradebook/studentdetail/courseSection/${courseSectionId}`);
};

const fetchEAQAssignmentPerformance = (courseSectionId: number): Promise<{ data: AssignmentPerformance[] }> => {
  if (!courseSectionId) {
    return Promise.resolve({ data: [] });
  }
  return facadeApi.get(`/eaq/assignmentPerformance?courseSectionId=${courseSectionId}`);
};

const fetchCourseSectionRoles = userId => facadeApi.get(`v1/eolsUser/${userId}/courseSection/roles`);

const fetchEAQFeatures = () => facadeApi.get(`/features/app/EAQ`);

const fetchGradebookData = (courseSectionId: number, attemptType: string, studentLimit = 1000): Promise<{ data: GradebookData }> => {
  if (!courseSectionId) {
    return Promise.resolve({ data: {} as GradebookData });
  }
  return perFacadeApi.get(`/gradebook/courseSection/${courseSectionId}?attemptType=${attemptType}&studentLimit=${studentLimit}`);
};

const fetchGradebookDataInExcel = (courseSectionId: number, attemptType: string, studentLimit = 1000) => {
  const timezone = encodeURIComponent(getTimeZone());
  return perFacadeApi.get(`/gradebook/export/courseSection/${courseSectionId}?attemptType=${attemptType}&studentLimit=${studentLimit}&timeZone=${timezone}`, {
    responseType: 'arraybuffer',
    data: null,
    headers: {
      'Content-Type': 'blob'
    }
  });
};

const fetchDashboardProgressing = (courseSectionId: number): Promise<{ data: DashboardProgressingDTO }> => {
  if (!courseSectionId) {
    return Promise.resolve({ data: {} as DashboardProgressingDTO });
  }
  return perFacadeApi.get(`/courseware/performanceEngagement/report?courseSectionId=${courseSectionId}`);
};

const fetchAssignmentSummary = (courseSectionId: number, contentType?: string): Promise<{ data: AssignmentSummary }> => {
  if (!courseSectionId) {
    return Promise.resolve({
      data: ({ metrics: [], card: null } as unknown) as AssignmentSummary
    });
  }
  const contentTypeQuery = contentType ? `&contentType=${contentType}` : '';
  return facadeApi.get(`/performancereport/assignmentSummaryV2?courseSectionId=${courseSectionId}${contentTypeQuery}`);
};

const fetchCWCourseList = (isActive?: boolean, isHideClassicCourse?: boolean): Promise<{ data: { courseCount: number; courseIds: number[] } }> => {
  const baseUrl = `/courseSections`;
  const params = new URLSearchParams();
  if (!isNil(isActive)) {
    params.append('isActive', isActive.toString());
  }
  if (!isNil(isHideClassicCourse)) {
    params.append('isHideClassicCourse', isHideClassicCourse.toString());
  }
  return perFacadeApi.get(`${baseUrl}?${params}`);
};

const fetchCourseAggregateData = async (courseSectionId: number, instructorId: number): Promise<{ data: CourseAggregateDTO[] }> => {
  const baseUrl = '/v2/courseware/courseAggregation';
  const params = new URLSearchParams();
  if (courseSectionId && instructorId) {
    params.append('courseSectionId', String(courseSectionId));
    params.append('instructorId', String(instructorId));
    try {
      const { data } = await perFacadeApi.get(`${baseUrl}?${params}`);
      return Promise.resolve({ data });
    } catch (error) {
      return Promise.resolve({
        data: [
          {
            courseSectionId,
            isEmpty: true
          }
        ] as CourseAggregateDTO[]
      });
    }
  }
  return Promise.resolve({ data: [] as CourseAggregateDTO[] });
};

const fetchCWCourseListByInstructor = (instructorId: number): Promise<{ data: CourseSection[] }> => {
  const baseUrl = '/v2/courseSections';
  const params = new URLSearchParams();
  if (instructorId) {
    params.append('instructorId', String(instructorId));
    return perFacadeApi.get(`${baseUrl}?${params}`);
  }
  return Promise.reject();
};

const fetchInstructorCourses = async (instructors: number[]): Promise<{ data: CourseSection[] }> => {
  try {
    const courseList = await Promise.allSettled(instructors.map(fetchCWCourseListByInstructor));
    if (courseList.some(v => v.status === 'rejected')) {
      return Promise.reject();
    }
    const courseMap = courseList.reduce((acc, curr, index) => {
      const currentData = curr.status === FULFILLED_STATUS ? curr.value.data : [];
      const remapCourses = currentData.map(v => ({ ...remapCourseSection(v), instructorId: instructors[index] }));
      return [...acc, ...remapCourses];
    }, []);
    return Promise.resolve({ data: courseMap });
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchInstructorAllCourseAggregates = async (instructorIds: number[]) => {
  if (!instructorIds?.length) {
    return Promise.resolve({ data: [] });
  }
  try {
    const fetchList = instructorIds.map(fetchAllCourseAggregations);
    const allCourseAggregates = await Promise.allSettled(fetchList);
    const remapCourseAggregates = (allCourseAggregates || []).reduce((acc, curr) => {
      const data = curr.status === FULFILLED_STATUS ? curr.value?.data?.content : [];
      return [...acc, ...(data || [])];
    }, []);
    return Promise.resolve({ data: remapCourseAggregates });
  } catch (error) {
    return Promise.resolve({ data: [] });
  }
};

const fetchCourseAggregateByCourseSections = async (courseSections: CourseSection[]) => {
  try {
    const courseMap = keyBy(courseSections, v => v.id || v.courseId);
    const courseAggregatePromises = courseSections.map(({ instructorId, id, courseId }) => fetchCourseAggregateData(Number(id || courseId), Number(instructorId)));
    const courseAggregates = await Promise.allSettled(courseAggregatePromises);
    const remapCourseAggregates = courseAggregates.reduce((acc, curr) => {
      const data = curr.status === FULFILLED_STATUS ? curr.value.data : [];
      const remapCourses = remapCourseAggregate(first(data), courseMap[first(data).courseSectionId]);
      return [...acc, remapCourses];
    }, []);
    return Promise.resolve({ data: remapCourseAggregates });
  } catch (error) {
    ELSLoggingService.error('report.service', `Error while attempting to fetch fetchCourseAggregateByCourseSections, error: ${error.message}`);
    return Promise.resolve({ data: [] });
  }
};

const fetchCourseAggregates = async (instructorId: number) => {
  try {
    const {
      data: { eolsUserIdsByUserNameAndType = [] }
    } = await fetchCrosswalkUser(String(instructorId), SystemType.EVOLVETYPE);

    const courseList = await Promise.all(eolsUserIdsByUserNameAndType.map(fetchCWCourseListByInstructor));
    const courseMap = courseList?.reduce((acc, curr, index) => {
      const remapCourses = curr.data.map(v => ({ ...v, instructorId: eolsUserIdsByUserNameAndType[index] }));
      return {
        ...acc,
        ...keyBy(remapCourses, v => v.id || v.courseId)
      };
    }, []);
    const courseAggregatePromises = mapValues(courseMap, course => fetchCourseAggregateData(course.id || course.courseId, course.instructorId));
    const courseAggregates = await Promise.all(Object.values(courseAggregatePromises));
    const remapCourseAggregates = courseAggregates.reduce((acc, curr) => {
      const { data } = curr;
      const remapCourses = remapCourseAggregate(first(data), courseMap[first(data).courseSectionId]);
      return [...acc, remapCourses];
    }, []);
    return Promise.resolve({ data: remapCourseAggregates });
  } catch (err) {
    ELSLoggingService.error('report.service', `Error while attempting to fetch CourseAggregates, error: ${err.message}`);
    return Promise.resolve({ data: [] });
  }
};

const fetchCourseAggregate = async (courseSectionIds: number[]): Promise<CourseAggregateDTO[]> => {
  if (!courseSectionIds?.length) {
    return Promise.resolve([]);
  }

  const uniqueCourseSectionIds = courseSectionIds.filter((value, index, array) => {
    return array.indexOf(value) === index;
  });

  const result: CourseAggregateDTO[] = [];
  const timezone = `tz=${encodeURIComponent(getTimeZone())}`;

  const fetchCourseSectionData = async (courseId: number) => {
    try {
      const { data } = await perFacadeApi.get(`/courseware/courseAggregation?courseSectionIds=${courseId}&${timezone}`);
      if (data.length) {
        result.push(...data);
      }
    } catch {
      result.push({
        courseSectionId: courseId,
        isEmpty: true,
        courseActive: false,
        courseCreationDate: ''
      } as CourseAggregateDTO);
    }
  };

  const fetchPromises = [];
  uniqueCourseSectionIds.forEach(courseId => {
    fetchPromises.push(fetchCourseSectionData(courseId));
  });

  await Promise.allSettled(fetchPromises);

  return result;
};

const fetchStudentHomeReport = (userId: number, courseSectionId: number, eaqISBN: number): Promise<{ data: StudentHomeReportDTO }> => {
  if (userId && courseSectionId && eaqISBN) {
    return perFacadeApi.get(`/studenthome/report?userId=${userId}&courseSectionId=${courseSectionId}&eaqISBN=${eaqISBN}`);
  }
  return Promise.resolve({ data: {} as StudentHomeReportDTO });
};

const fetchEngagementSummary = (courseSectionId: number): Promise<{ data: EngagementSummaryDTO }> => {
  if (!courseSectionId) {
    return Promise.resolve({ data: {} as EngagementSummaryDTO });
  }
  return perFacadeApi.get(`/engagementSummary/reportV2?courseSectionId=${courseSectionId}`);
};

const isEnabledGlobally = featureItems => {
  return JSON.parse(
    (
      featureItems.find(item => ['ALL', null].includes(item.group)) || {
        featureValue: 'false'
      }
    ).featureValue
  );
};

const isEnabledAtGroupLevel = (featureItems, groupValue) => {
  return JSON.parse(
    (
      featureItems.find(item => item.group === groupValue.toString()) || {
        featureValue: 'false'
      }
    ).featureValue
  );
};

const isEnabled = (feature, groupValue, eaqFeatures) => {
  const featureItems = eaqFeatures.filter(item => item.featureName === feature.featureName);
  const isFeatureEnabledGlobally = isEnabledGlobally(featureItems);

  if (groupValue) {
    return isFeatureEnabledGlobally || isEnabledAtGroupLevel(featureItems, groupValue);
  }
  return isFeatureEnabledGlobally;
};

const isFeatureEnabled = (feature, currentCourseId, eaqFeatures) => {
  if (currentCourseId) {
    return isEnabled(feature, currentCourseId, eaqFeatures);
  }
  return false;
};

const fetchCWAssignmentByCourseSectionId = (courseSectionId: number): Promise<{ data: CWAssignment[] }> => {
  const baseUrl = '/v2/assignment';
  const params = new URLSearchParams();
  if (courseSectionId) {
    params.append('courseSectionId', String(courseSectionId));
    return facadeApi.get(`${baseUrl}?${params}`);
  }
  return Promise.resolve({ data: [] as CWAssignment[] });
};

const fetchStudentHomeAssignmentsReport = (userId: number, courseSectionId: number): Promise<{ data: StudentHomeAssignmentReportDTO[] }> => {
  if (userId && courseSectionId) {
    return perFacadeApi.get(`/studenthome/report/assignments?userId=${userId}&courseSectionId=${courseSectionId}`);
  }
  return Promise.resolve({ data: [] as StudentHomeAssignmentReportDTO[] });
};

export {
  asTimestring,
  asTimestringFormated,
  asTimestamp,
  convertNegativeDuration,
  displayNumberWithSign,
  durationAsTimestring,
  fetchAssignmentInfo,
  fetchAssignmentDetail,
  fetchCourseSectionRoles,
  fetchInstructorAllCourseAggregates,
  fetchCourseAggregateByCourseSections,
  fetchInstructorCourses,
  fetchCWCourseList,
  fetchEAQFeatures,
  fetchEngagementSummary,
  fetchStudentEngagements,
  fetchAssignmentEngagements,
  fetchCourseAggregate,
  fetchStudentDetailReport,
  fetchEAQAssignmentPerformance,
  fetchGradebookData,
  fetchGradebookDataInExcel,
  fetchDashboardProgressing,
  fetchStudentHomeReport,
  fetchGrabookStudentDetailReport,
  getAssignmentTypeTranslateText,
  getAvgStudentsAchievedMastery,
  getGradeTypeDisplayName,
  getGradeValue,
  getAssignmentGradeBasedOnGradeTypeAndScore,
  getMasteryAssignmentGoal,
  getMasteryGoalText,
  getNonMasteryAssignmentGoal,
  isFeatureEnabled,
  remapFieldsForEngagementReport,
  remapFieldsForAssignmentDetailReport,
  remapFieldsForAssignmentEngagementTable,
  remapFieldsForAssignmentInfo,
  transformAssignmentStatus,
  fetchAssignmentSummary,
  fetchCWCourseListByInstructor,
  fetchCourseAggregateData,
  fetchCourseAggregates,
  fetchCWAssignmentByCourseSectionId,
  fetchStudentHomeAssignmentsReport
};
