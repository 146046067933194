export const PS_PILL_TITLES = {
  PS: 'Program Success Dashboard',
  SHERPATH: 'Build Knowledge | Sherpath',
  SHADOW_HEALTH: 'Apply Clinical Judgment | Shadow Health',
  HESI: 'Assess & Review | HESI'
};

export const DEFAULT_SH_DATA = {
  data: [],
  fallbackMessage: ''
};

export const PS_SHERPATH_ASSIGNMENT_SCORE_COLOR = {
  RED: 'u-els-fill-warn',
  GREEN: 'u-els-fill-extended-green-8'
};

export const PS_SHERPATH_ASSIGNMENT_SCORE_THRESHOLD = 65;
