import { configureHandler } from 'mocks/helpers';
import firstAttempt from './_data/firstAttempt';
import lastAttempt from './_data/lastAttempt';
import bestAttemptBDD from './_data/bestAttemptBDD';
import classAverageBDD from './_data/classAverageBDD';


const data = {
  FIRST_ATTEMPT: firstAttempt,
  FIRST_ATTEMPT_BEFORE_DUE_DATE: firstAttempt,
  LAST_ATTEMPT: lastAttempt,
  LAST_ATTEMPT_BEFORE_DUE_DATE: lastAttempt,
  BEST_ATTEMPT: bestAttemptBDD,
  BEST_ATTEMPT_BEFORE_DUE_DATE:bestAttemptBDD,
  AVERAGE_SCORE: classAverageBDD,
  AVERAGE_SCORE_BEFORE_DUE_DATE: classAverageBDD
}



export default configureHandler(async (req, res, ctx) => {
  const queryParams = req.url.searchParams;
  const attemptType = queryParams.get('attemptType');

  if (!data[attemptType]) {
    return req.passthrough();
  }

  return res(ctx.status(200), ctx.json(data[attemptType]));
});