import moment from 'moment';
import { inRange, groupBy, difference } from 'lodash';

import { ELSLoggingService } from 'components/common';
import { MenuOption } from 'models';

import { ASSIGNMENT_DUE_MENU_NEXT_7_DAYS, ASSIGNMENT_DUE_MENU_LAST_30_DAYS, ASSIGNMENT_DUE_MENU_LAST_7_DAYS } from 'reports/cw/constants/cw.constant';
import { ASSIGNMENT_TYPES, REPORT_DEPENDENT_OPTIONS as dependentList } from 'reports/cw/constants/report.constant';
import { CatalogDto } from 'reports/ps/models';
import { convertQuizTypeToType, getHiddenDependentOption } from 'reports/cw/helpers/report.helper';
import { GradeType, StudentHomeReportMetric, CWAssignmentFilter, StudentDetailAssignment, AssignmentEngagementMetricDTO, CourseSection, CWAssignment } from 'reports/cw/models';

export type AssignmentMetric = StudentHomeReportMetric | StudentDetailAssignment | AssignmentEngagementMetricDTO;

function isStudentHomeReportMetric(assignment: AssignmentMetric): assignment is StudentHomeReportMetric {
  const asm = assignment as StudentHomeReportMetric;
  return asm.gradeType !== undefined;
}

function isStudentDetailAssignment(assignment: AssignmentMetric): assignment is StudentDetailAssignment {
  const asm = assignment as StudentDetailAssignment;
  return asm.contentType !== undefined;
}

function isAssignmentEngagementMetricDTO(assignment: AssignmentMetric): assignment is AssignmentEngagementMetricDTO {
  const asm = assignment as AssignmentEngagementMetricDTO;
  return asm.assignmentType !== undefined && asm.assignmentGradeType !== undefined;
}

export const getAssignmentCountByType = (assignments: AssignmentMetric[], assignmentType: string) => {
  const dependentOptions = dependentList.find(item => item.parentOption === assignmentType)?.dependentOptionlist || [];
  return assignments.filter(asm => {
    if (isStudentDetailAssignment(asm)) {
      return asm.contentType === assignmentType || dependentOptions?.includes(asm.contentType);
    }
    return asm.assignmentType === assignmentType || dependentOptions.includes(asm.assignmentType);
  }).length;
};

export const buildAssignmentTypeMenu = (assignments: AssignmentMetric[], assignmentTypes: string[]): MenuOption[] => {
  return assignmentTypes.map(type => {
    return {
      key: type,
      name: `${convertQuizTypeToType(type)?.name} (${getAssignmentCountByType(assignments, type)})`
    };
  });
};

export const getAssignmentTypes = (assignments: (StudentHomeReportMetric | AssignmentEngagementMetricDTO)[] = []): string[] => {
  const validTypes = Object.values(ASSIGNMENT_TYPES).map(assignmentType => assignmentType.id);
  const allAssignmentTypes = Object.keys(groupBy(assignments, 'assignmentType'));
  const invalidTypes = difference(allAssignmentTypes, validTypes).join();
  if (invalidTypes.length) {
    ELSLoggingService.error('getAssignmentTypes', `ASSIGNMENT TYPE NOT FOUND: ${invalidTypes}`);
  }
  return allAssignmentTypes.filter(assignmentType => validTypes.includes(assignmentType));
};

export const getContentTypes = (assignments: StudentDetailAssignment[] = []): string[] => {
  const validTypes = Object.values(ASSIGNMENT_TYPES).map(assignmentType => assignmentType.id);
  const allAssignmentTypes = Object.keys(groupBy(assignments, 'contentType'));
  const invalidTypes = difference(allAssignmentTypes, validTypes).join();
  if (invalidTypes.length) {
    ELSLoggingService.error('getContentTypes', `CONTENT TYPE NOT FOUND: ${invalidTypes}`);
  }
  return allAssignmentTypes.filter(assignmentType => validTypes.includes(assignmentType));
};

export const renderAssignmentTypeMenuLabel = (
  displayAssignments: AssignmentMetric[],
  { checkedKeys, assignmentTypeMenuOptions }: { checkedKeys: string[]; assignmentTypeMenuOptions: MenuOption[] }
): string => {
  const hideList = getHiddenDependentOption(
    dependentList?.flatMap(option => option.dependentOptionlist),
    assignmentTypeMenuOptions
  );
  if (checkedKeys.length === assignmentTypeMenuOptions.length) {
    return `All (${displayAssignments.length})`;
  }
  const sortedCheckedKeys = checkedKeys.sort((k1, k2) => convertQuizTypeToType(k1)?.name.localeCompare(convertQuizTypeToType(k2)?.name));

  const filteredCheckedKeys = sortedCheckedKeys.filter(type => !hideList.includes(type));

  return filteredCheckedKeys
    .map(type => {
      return `${convertQuizTypeToType(type)?.name} (${getAssignmentCountByType(displayAssignments, type)})`;
    })
    .filter(Boolean)
    .join(', ');
};

export const filterAssignmentByName = <T extends AssignmentMetric>(assignments: T[], name: string) => {
  return (assignments || []).filter(assignment => {
    if (isStudentDetailAssignment(assignment)) {
      return assignment.title
        ?.toLowerCase()
        .trim()
        .includes(name?.toLowerCase().trim());
    }
    if (isStudentHomeReportMetric(assignment) || isAssignmentEngagementMetricDTO(assignment)) {
      return assignment.assignmentName
        ?.toLowerCase()
        .trim()
        .includes(name?.toLowerCase().trim());
    }
    return false;
  });
};

export const filterAssignmentByCheckKeys = <T extends AssignmentMetric>(assignments: T[], assignmentTypeMenuCheckedKeys: string[]) => {
  return assignments.filter(assignment => {
    if (isStudentDetailAssignment(assignment)) {
      return assignmentTypeMenuCheckedKeys.includes(assignment.contentType);
    }
    if (isStudentHomeReportMetric(assignment) || isAssignmentEngagementMetricDTO(assignment)) {
      return assignmentTypeMenuCheckedKeys.includes(assignment.assignmentType);
    }
    return false;
  });
};

export const filterAssignmentByRangeDate = <T extends AssignmentMetric>(assignments: T[], assignmentDueMenuCheckedKey: string) => {
  switch (assignmentDueMenuCheckedKey) {
    case ASSIGNMENT_DUE_MENU_LAST_7_DAYS.key:
      return assignments.filter(assignment =>
        moment(assignment.dueDate).isBetween(
          moment()
            .subtract(ASSIGNMENT_DUE_MENU_LAST_7_DAYS.value, 'days')
            .startOf('day'),
          moment().startOf('day')
        )
      );
    case ASSIGNMENT_DUE_MENU_NEXT_7_DAYS.key:
      return assignments.filter(assignment =>
        moment(assignment.dueDate).isBetween(
          moment()
            .add(1, 'day')
            .startOf('day'),
          moment()
            .add(ASSIGNMENT_DUE_MENU_NEXT_7_DAYS.value + 1, 'days')
            .startOf('day')
        )
      );
    case ASSIGNMENT_DUE_MENU_LAST_30_DAYS.key:
      return assignments.filter(assignment =>
        inRange(
          moment()
            .startOf('day')
            .diff(moment(assignment.dueDate).startOf('day'), 'days'),
          ASSIGNMENT_DUE_MENU_LAST_30_DAYS.value + 1
        )
      );
    default:
      return assignments;
  }
};

export const filterAssignmentByGradedOnly = <T extends AssignmentMetric>(assignments: T[], filteredByGradeOnly: boolean) => {
  if (filteredByGradeOnly)
    return assignments.filter(assignment => {
      if (isStudentDetailAssignment(assignment) || isStudentHomeReportMetric(assignment)) {
        return assignment.gradeType !== GradeType.NOT_GRADED.valueOf();
      }
      if (isAssignmentEngagementMetricDTO(assignment)) {
        return assignment.assignmentGradeType !== GradeType.NOT_GRADED.valueOf();
      }
      return true;
    });
  return assignments;
};

export const getFilteredAssignmentData = <T extends AssignmentMetric>(assignments: T[], filter: CWAssignmentFilter) => {
  const { searchKeyword, assignmentTypeMenuCheckedKeys, assignmentDueMenuCheckedKey, filteredGradedOnly } = filter;
  const assignmentByName = filterAssignmentByName(assignments, searchKeyword);
  const assignmentsByDate = filterAssignmentByRangeDate(assignmentByName, assignmentDueMenuCheckedKey);
  const assignmentByGradedOnly = filterAssignmentByGradedOnly(assignmentsByDate, filteredGradedOnly);

  const displayAssignments = filterAssignmentByCheckKeys(assignmentByGradedOnly, assignmentTypeMenuCheckedKeys);
  return {
    displayAssignments,
    assignmentsByDate,
    assignmentByGradedOnly
  };
};

export const getInteractiveReviewBody = (courseSectionInfo: CourseSection, catalog: CatalogDto, assignments: CWAssignment[], assignmentId: number) => {
  const foundAssignment = assignments?.find(a => a?.id === assignmentId);
  const resource = catalog?.data?.find(c => c?.attributes?.contentId === foundAssignment?.contentId);
  return {
    courseEntitlements: courseSectionInfo.entitlements,
    catalogItem: resource,
    courseSectionId: courseSectionInfo.id
  };
};
