import { ELSAdobeAnalyticService, ELSCommonUIConstants } from '@els/els-ui-common-react';

export const trackAAEvent = (eventType: string, analyticsObj: object): void => {
  const aaPageData = window.pageData;
  const locVisitorObj = aaPageData.visitor;
  const locPageObj = aaPageData.page;
  const locEducationObj = aaPageData.education;
  const locAnalyticsObj = analyticsObj;

  const analyticsObjKeys = Object.keys(locAnalyticsObj);
  const pageKeys = Object.keys(aaPageData.page);
  const visitorKeys = Object.keys(aaPageData.visitor);

  analyticsObjKeys.forEach(analyticsKey => {
    if (visitorKeys.includes(analyticsKey)) {
      locVisitorObj[analyticsKey] = locAnalyticsObj[analyticsKey];
      delete locAnalyticsObj[analyticsKey];
    }

    if (pageKeys.includes(analyticsKey)) {
      locPageObj[analyticsKey] = locAnalyticsObj[analyticsKey];
      delete locAnalyticsObj[analyticsKey];
    }

    if (pageKeys.includes(analyticsKey)) {
      locEducationObj[analyticsKey] = locAnalyticsObj[analyticsKey];
      delete locAnalyticsObj[analyticsKey];
    }
  });

  ELSAdobeAnalyticService.trackEvent(eventType, {
    ...locAnalyticsObj,
    ...aaPageData,
    visitor: locVisitorObj,
    page: locPageObj,
    education: locEducationObj
  });
};

export const trackAAFeature = (feature: string): void => {
  ELSAdobeAnalyticService.trackEvent(ELSCommonUIConstants.analytics.eventTypes.productFeatureUsed, {
    ...window.pageData,
    feature: {
      name: feature
    }
  });
};
