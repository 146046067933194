import { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ELSWithModalService } from '@els/els-component-modal-react';
import DemoInit from 'components/demo-init/DemoInit';
import { Location } from 'history';
import { routerSelectors } from 'redux/ducks/route';
import { ELSModalServiceType } from 'models';
import { getAdobeProductCode } from 'helpers/app.helper';
import { appSelectors } from 'redux/ducks/app';
import { ELSTokenHelper } from '..';

interface LocationChangeHandlerProps {
  location: Location;
  modalService: ELSModalServiceType;
  role: string;
}

const pathname = 'pathname';

class LocationChangeHandler extends Component<LocationChangeHandlerProps> {
  componentDidMount() {
    this.updateAdobeProductCodeByLocation();
  }

  componentDidUpdate({ location }) {
    if (location && this.props.location && location[pathname] !== this.props.location[pathname]) {
      this.props.modalService.closeAllModal();
      this.updateAdobeProductCodeByLocation();
    }
    /* eslint-disable no-underscore-dangle */
    window._mfq = window._mfq || [];
    const regexp = new RegExp('/#', 'g');
    const href = window.location.href.replace(regexp, '');
    window._mfq.push(['newPageView', href]);
  }

  get userRole() {
    let { role } = this.props;
    const token = ELSTokenHelper?.getToken();
    if (token) {
      const tokenData = ELSTokenHelper?.parseToken(token);
      role = role ?? tokenData?.role;
    }
    return role;
  }

  updateAdobeProductCodeByLocation = () => {
    const adobeProductCode = getAdobeProductCode(this.props.location[pathname]);

    if (window?.pageData?.page?.productName !== adobeProductCode) {
      window.pageData = { ...window.pageData, page: { ...window.pageData?.page, productName: adobeProductCode } };
    }
    window.pageData = {
      ...window.pageData,
      visitor: {
        ...window.pageData?.visitor,
        details: this.userRole
      }
    };
  };

  render() {
    return <DemoInit />;
  }
}

const mapStateToProps = createStructuredSelector({
  location: routerSelectors.getLocation,
  role: appSelectors.getUserRole
});

export default connect(mapStateToProps)(ELSWithModalService(LocationChangeHandler));
