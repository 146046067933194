import { cloneDeep } from 'lodash';
import { ELSInlineLoader } from '@els/els-ui-common-react';
import classnames from 'classnames';
import cx from 'classnames';
import moment from 'moment';
import React, { Component, ReactElement, forwardRef, createElement, lazy, Suspense } from 'react';

import { buildEmptyRowsState, buildEmptyRowState } from 'services/filter.service';
import { Collapse, CollapsePanel } from 'components/common/collapse';
import { ELSTextBox, ELSCheckBox, ELSSlideSwitch, ELSDate, ELSIcon, ELSDropDown } from 'components/common';
import { MULTIPLE_SELECTED_DISPLAY } from 'constants/filter.constant';
import { TABLE_FILTER_DATE_FORMAT, TABLE_FILTER_DATE_EVENT, TABLE_FILTER_MONTH_YEAR_FORMAT, TABLE_FILTER_MONTH_YEAR_DISPLAY_FORMAT, PRESS_KEYS } from 'constants/app.constant';
import { TableFilterRowConfig, TableFilterRowsState, TableFilterRowState, TableFilterRowType } from 'models';
import 'react-datepicker/dist/react-datepicker.css';
import SearchBar from '../search-bar/SearchBar';
import { CheckboxMenu } from '../select-menu';

const DatePicker = lazy(() => import('../date-picker/DatePicker'));
const PerformanceReportModal = lazy(() => import('components/common/performance-report-modal/PerformanceReportModal'));
const TableFilterTag = lazy(() => import('./table-filter-tag/TableFilterTag'));

export interface TableFilterProps {
  rowsConfig?: TableFilterRowConfig[];
  rowsState?: TableFilterRowsState;
  searchKeyword?: string;
  onFilter?: Function;
  onSearch?: Function;
  searchPlaceholder?: string;
  customClass?: string;
  filterHidden?: boolean;
  searchHidden?: boolean;
  title?: string | ReactElement;
  description?: string;
  toggleFilterTitle?: string;
  toggleFilterChecked?: boolean;
  onToggleFilter?: Function;
  onFilterButtonClicked?: Function;
}

interface TableFilterState {
  editingRowsState: TableFilterRowsState;
  modalVisible: boolean;
  isPressEsc: boolean;
}

export const CustomInputDatePicker = (props, ref) => {
  return (
    <div ref={ref} id={props.id ? `field-wrap-${props.id}` : undefined} className="c-els-field">
      <div className="c-els-field__label">
        <span className="c-els-field__wrap c-els-field__wrap--icon-right">
          <ELSIcon name="calendar" direction="right" />
          <input {...props} className="c-els-field__input c-table-filter-modal__month-year-row-custom-input" />
        </span>
      </div>
    </div>
  );
};
class TableFilter extends Component<TableFilterProps, TableFilterState> {
  constructor(props: TableFilterProps) {
    super(props);
    const { rowsState } = props;
    this.state = {
      editingRowsState: cloneDeep(rowsState),
      modalVisible: false,
      isPressEsc: false
    };
  }

  handleSetIsPressEsc = (val: boolean) => {
    this.setState({ isPressEsc: val });
  };

  updateState = (row: TableFilterRowConfig, value: string | number | boolean | string[], field?: string): void => {
    const filterData: TableFilterRowState = this.state.editingRowsState[row.field] || buildEmptyRowState(row.type);
    switch (row.type) {
      case TableFilterRowType.DECIMAL_RANGE: {
        filterData[field] = parseFloat(value.toString());
        filterData.hasData = !!(filterData.from || filterData.to);
        break;
      }
      case TableFilterRowType.RANGE: {
        filterData[field] = parseInt(value.toString(), 10);
        filterData.hasData = !!(filterData.from || filterData.to);
        break;
      }
      case TableFilterRowType.MULTI_SELECT: {
        let { checkedKeys } = filterData;
        if (checkedKeys.includes(value.toString())) {
          checkedKeys = checkedKeys.filter(key => key !== value);
        } else {
          checkedKeys.push(value.toString());
        }
        filterData.checkedKeys = checkedKeys;
        filterData.hasData = !!checkedKeys.length;
        break;
      }
      case TableFilterRowType.SLIDE_SWITCH: {
        filterData.isChecked = !!value;
        filterData.hasData = true;
        break;
      }
      case TableFilterRowType.DATE_RANGE:
      case TableFilterRowType.MONTH_RANGE: {
        filterData[field] = value;
        filterData.hasData = !!(filterData.from || filterData.to);
        break;
      }
      case TableFilterRowType.DROPDOWN: {
        filterData.selectedOptionValue = value.toString();
        filterData.hasData = !!value;
        break;
      }
      case TableFilterRowType.CHECKBOX_MENU: {
        const checkedKeys = value as string[];
        filterData.checkedKeys = checkedKeys;
        if (row.options.length === checkedKeys.length) {
          filterData.hasData = false;
        } else {
          filterData.hasData = !!checkedKeys.length;
        }
        break;
      }
      default: {
        break;
      }
    }
    this.setState(prevState => ({
      editingRowsState: { ...prevState.editingRowsState, [row.field]: filterData }
    }));
  };

  renderMonthYearRangeRow = (rowConfig: TableFilterRowConfig) => {
    const rowState = this.state.editingRowsState[rowConfig.field];
    return (
      <div key={rowConfig.key} className="c-table-filter-modal__row c-table-filter-modal__date-range-row">
        <span className="u-els-font-size-body-large c-table-filter-modal__range-row-label">{rowConfig.label}</span>
        <Suspense fallback={<ELSInlineLoader />}>
          <div className="c-table-filter-modal__range-row-input-container">
            <DatePicker
              value={rowState.from}
              handleCallback={(date: Date) => {
                this.dateRangeChangeHandler(TABLE_FILTER_DATE_EVENT.startDateChange, date, rowConfig);
              }}
              dateFormat={TABLE_FILTER_MONTH_YEAR_FORMAT}
              placeholderText={TABLE_FILTER_MONTH_YEAR_DISPLAY_FORMAT}
              customInput={createElement(forwardRef(CustomInputDatePicker))}
              showMonthYearPicker
              handleFocusCallback={this.handleSetIsPressEsc}
            />
            <span className="u-els-margin-1o2 c-table-filter-modal__range-row-to">to</span>
            <DatePicker
              value={rowState.to}
              handleCallback={(date: Date) => {
                if (date) {
                  const endOfMonth = moment(date)
                    .endOf('month')
                    .toDate();
                  this.dateRangeChangeHandler(TABLE_FILTER_DATE_EVENT.endDateChange, endOfMonth, rowConfig);
                } else {
                  this.dateRangeChangeHandler(TABLE_FILTER_DATE_EVENT.endDateChange, null, rowConfig);
                }
              }}
              dateFormat={TABLE_FILTER_MONTH_YEAR_FORMAT}
              placeholderText={TABLE_FILTER_MONTH_YEAR_DISPLAY_FORMAT}
              customInput={createElement(forwardRef(CustomInputDatePicker))}
              minDate={rowState.from ? moment(rowState.from).toDate() : null}
              showMonthYearPicker
              handleFocusCallback={this.handleSetIsPressEsc}
            />
          </div>
        </Suspense>
      </div>
    );
  };

  renderRangeRow = (rowConfig: TableFilterRowConfig): ReactElement => {
    const rowState = this.state.editingRowsState[rowConfig.field];
    const containerClasses = classnames('c-table-filter-modal__row c-table-filter-modal__range-row', {
      'c-table-filter-modal__range-row--percent': rowConfig.isPercent
    });
    return (
      <div key={rowConfig.key} className={containerClasses}>
        <div className="u-els-font-size-body-large c-table-filter-modal__range-row-label">{rowConfig.label}</div>
        <div className="c-table-filter-modal__range-row-input-container">
          <ELSTextBox
            type="number"
            cssClasses="c-table-filter-modal__range-row-input"
            placeholder={rowConfig?.min || '0'}
            value={rowState?.from || ''}
            changeHandler={evt => {
              this.updateState(rowConfig, evt.target.value, 'from');
            }}
          />
          <span className="c-table-filter-modal__range-row-to">to</span>
          <ELSTextBox
            type="number"
            cssClasses="c-table-filter-modal__range-row-input"
            placeholder={rowConfig?.max || 'max'}
            value={rowState?.to || ''}
            changeHandler={evt => {
              this.updateState(rowConfig, evt.target.value, 'to');
            }}
          />
        </div>
      </div>
    );
  };

  renderMultiSelectRow = (rowConfig: TableFilterRowConfig): ReactElement => {
    const rowState = this.state.editingRowsState[rowConfig.field];
    const checkedKeys = rowState?.checkedKeys || [];
    return (
      <div key={rowConfig.key} className="c-table-filter-modal__row c-table-filter-modal__multi-select-row">
        <div className="u-els-padding-bottom-1x c-table-filter-modal__multi-select-row-label">
          <span className="u-els-font-size-body-large">{rowConfig.label}</span>
          <span className="u-els-padding-left-1x u-els-font-size-meta u-els-color-n7">Select all that apply</span>
        </div>
        <div className="c-table-filter-modal__multi-select-row-checkbox">
          {rowConfig.options.map(option => (
            <ELSCheckBox
              key={option.key}
              name={option.key}
              value={option.name}
              checked={checkedKeys.includes(option.key)}
              changeHandler={() => {
                this.updateState(rowConfig, option.key);
              }}
            >
              {option.name}
            </ELSCheckBox>
          ))}
        </div>
      </div>
    );
  };

  renderSlideSwitchRow = (rowConfig: TableFilterRowConfig): ReactElement => {
    const rowState = this.state.editingRowsState[rowConfig.field];
    return (
      <div key={rowConfig.key} className="c-table-filter-modal__row">
        <ELSSlideSwitch
          id="defaultSlideSwitch"
          name="defaultSlideSwitch"
          checked={rowState?.isChecked || false}
          changeHandler={evt => {
            this.updateState(rowConfig, evt.target.checked);
          }}
          labelStateIsOn={rowConfig.label}
          labelStateIsOff={rowConfig.label}
          size="small"
        />
      </div>
    );
  };

  renderDropdownRow = (rowConfig: TableFilterRowConfig): ReactElement => {
    const rowState = this.state.editingRowsState[rowConfig.field];
    return (
      <div
        key={rowConfig.key}
        className={cx('c-table-filter-modal__row c-table-filter-modal__dropdown-row', { 'c-table-filter-modal__dropdown-row-all-items': !rowState.selectedOptionValue })}
      >
        <div className="u-els-font-size-body-large c-table-filter-modal__range-row-label">{rowConfig.label}</div>
        <ELSDropDown
          id={rowConfig.key}
          name={rowConfig.field}
          changeHandler={evt => this.updateState(rowConfig, evt.target.value)}
          options={rowConfig.dropdownOptions}
          value={rowState.selectedOptionValue}
        />
      </div>
    );
  };

  renderCheckboxMenuRow = (rowConfig: TableFilterRowConfig): ReactElement => {
    const rowState = this.state.editingRowsState[rowConfig.field];
    const checkedKeys = rowState?.checkedKeys || [];
    return (
      <div key={rowConfig.key} className={cx('c-table-filter-modal__row c-table-filter-modal__checkbox-menu-row')}>
        <div className="u-els-font-size-body-large c-table-filter-modal__range-row-label">{rowConfig.label}</div>
        <CheckboxMenu
          inline
          allOptionDisplayTitle={rowConfig.placeHolder}
          customMultiSelectedLabel={MULTIPLE_SELECTED_DISPLAY}
          checkedKeys={checkedKeys}
          onOptionChange={(keys: string[]) => this.updateState(rowConfig, keys)}
          options={rowConfig.options}
        />
      </div>
    );
  };

  dateRangeChangeHandler = (evt: any, selectedDay: Date, rowConfig: TableFilterRowConfig): void => {
    const dateValue = moment(selectedDay).format(TABLE_FILTER_DATE_FORMAT);
    const defaultValue = dateValue === 'Invalid date' ? 0 : dateValue;
    if (evt === TABLE_FILTER_DATE_EVENT.startDateChange) {
      this.updateState(rowConfig, defaultValue, 'from');
    } else if (evt === TABLE_FILTER_DATE_EVENT.endDateChange) {
      this.updateState(rowConfig, defaultValue, 'to');
    }
  };

  datePickerParser = (str: string) => {
    const valid = moment(str, TABLE_FILTER_DATE_FORMAT, true).isValid();
    return valid ? moment(str, TABLE_FILTER_DATE_FORMAT, true).toDate() : undefined;
  };

  renderDateRangeRow = (rowConfig: TableFilterRowConfig): ReactElement => {
    const rowState = this.state.editingRowsState[rowConfig.field];
    return (
      <div key={rowConfig.key} className="c-table-filter-modal__row c-table-filter-modal__date-range-row">
        <span className="u-els-font-size-body-large c-table-filter-modal__range-row-label">Date Taken</span>
        <div className="c-table-filter-modal__range-row-input-container">
          <span className="c-els-field c-table-filter-modal__range-row--date">
            <ELSDate
              key={rowState?.from}
              iconRight="calendar"
              parseDate={this.datePickerParser}
              formatDate={date => moment(date).format(TABLE_FILTER_DATE_FORMAT)}
              placeHolder={TABLE_FILTER_DATE_FORMAT.toLowerCase()}
              value={rowState?.from ? moment(rowState?.from).toDate() : null}
              disableAfter={moment(rowState?.to, TABLE_FILTER_DATE_FORMAT).toDate()}
              changeHandler={(evt = TABLE_FILTER_DATE_EVENT.startDateChange, selectedDay) => this.dateRangeChangeHandler(evt, selectedDay, rowConfig)}
            />
          </span>
          <span className="u-els-margin-1o2 c-table-filter-modal__range-row-to">to</span>
          <span className="c-els-field c-table-filter-modal__range-row--date">
            <ELSDate
              key={rowState?.to}
              iconRight="calendar"
              parseDate={this.datePickerParser}
              formatDate={date => moment(date).format(TABLE_FILTER_DATE_FORMAT)}
              placeHolder={TABLE_FILTER_DATE_FORMAT.toLowerCase()}
              value={rowState?.to ? moment(rowState?.to).toDate() : null}
              disableBefore={moment(rowState?.from, TABLE_FILTER_DATE_FORMAT).toDate()}
              changeHandler={(evt = TABLE_FILTER_DATE_EVENT.endDateChange, selectedDay) => this.dateRangeChangeHandler(evt, selectedDay, rowConfig)}
            />
          </span>
        </div>
      </div>
    );
  };

  renderRow = (row: TableFilterRowConfig): ReactElement => {
    switch (row.type) {
      case TableFilterRowType.RANGE:
      case TableFilterRowType.DECIMAL_RANGE: {
        return this.renderRangeRow(row);
      }
      case TableFilterRowType.MULTI_SELECT: {
        return this.renderMultiSelectRow(row);
      }
      case TableFilterRowType.SLIDE_SWITCH: {
        return this.renderSlideSwitchRow(row);
      }
      case TableFilterRowType.DATE_RANGE: {
        return this.renderDateRangeRow(row);
      }
      case TableFilterRowType.MONTH_RANGE: {
        return this.renderMonthYearRangeRow(row);
      }
      case TableFilterRowType.DROPDOWN: {
        return this.renderDropdownRow(row);
      }
      case TableFilterRowType.CHECKBOX_MENU: {
        return this.renderCheckboxMenuRow(row);
      }
      default: {
        return <></>;
      }
    }
  };

  renderDesktopModal = (): ReactElement => {
    return (
      <>
        {this.props.description && (
          <div className="c-table-filter-modal__row c-table-filter-modal__description-row">
            <div className="u-els-font-size-body-large c-table-filter-modal__description-row-content">{this.props.description}</div>
          </div>
        )}
        {this.props.rowsConfig.map(row => this.renderRow(row))}
      </>
    );
  };

  renderMobileModal = (): ReactElement => {
    return (
      <Collapse activeKey={[]}>
        {this.props.rowsConfig.map(row => {
          return (
            <CollapsePanel key={row.key} labelKey={row.key} header={row.label}>
              {this.renderRow(row)}
            </CollapsePanel>
          );
        })}
      </Collapse>
    );
  };

  renderFooter = (): ReactElement => {
    return (
      <div className="u-els-margin-top-2x u-els-margin-top-none@mobile c-performance-report-modal__footer c-table-filter-modal__footer">
        <div className="u-els-display-none@mobile c-table-filter-modal__footer-cancel-container">
          <button
            type="button"
            onClick={this.onCancelBtnClicked}
            onKeyDown={evt => this.onButtonKeyDown(evt, this.onCancelBtnClicked)}
            className="c-table-filter-modal__footer-cancel"
          >
            <ELSIcon name="close" color="secondary" size="1x" customClass="c-table-filter-modal__footer-cancel-icon" />
            <span className="u-els-padding-left-3o4">Cancel</span>
          </button>
        </div>
        <button
          type="button"
          className="c-table-filter-modal__footer-reset"
          onClick={this.onResetFilterBtnClicked}
          onKeyDown={evt => this.onButtonKeyDown(evt, this.onResetFilterBtnClicked)}
        >
          Reset Filters
        </button>
        <button
          type="button"
          className="c-els-button c-table-filter-modal__footer-apply"
          onClick={this.onApplyFilterBtnClicked}
          onKeyDown={evt => this.onButtonKeyDown(evt, this.onApplyFilterBtnClicked)}
        >
          Apply Filters
        </button>
      </div>
    );
  };

  onButtonKeyDown = (evt, callback: Function): void => {
    const { key } = evt;
    if (key === PRESS_KEYS.ENTER || key === PRESS_KEYS.SPACE) {
      evt.preventDefault();
      callback();
      document.hasFocus();
    }
  };

  onCancelBtnClicked = (): void => {
    this.setState({
      modalVisible: false,
      editingRowsState: cloneDeep(this.props.rowsState)
    });
  };

  onResetFilterBtnClicked = (): void => {
    this.setState({ editingRowsState: buildEmptyRowsState(this.props.rowsConfig) });
  };

  onResetAllFilterBtnClicked = (): void => {
    const { rowsConfig } = this.props;
    const editingRowsState = buildEmptyRowsState(rowsConfig);
    this.setState({ editingRowsState });
    this.props.onFilter(cloneDeep(editingRowsState));
  };

  onApplyFilterBtnClicked = (): void => {
    this.setState({ modalVisible: false });
    this.props.onFilter(cloneDeep(this.state.editingRowsState));
  };

  onTagClicked = (config: TableFilterRowConfig): void => {
    const { key, type } = config;
    const editingRowsState = { ...cloneDeep(this.props.rowsState), [key]: buildEmptyRowState(type) };
    this.setState({ editingRowsState });
    this.props.onFilter(cloneDeep(editingRowsState));
  };

  renderTags = (): ReactElement => {
    const { rowsConfig, rowsState } = this.props;
    const filterKeys = Object.keys(rowsState).filter(key => (rowsState[key].type === TableFilterRowType.SLIDE_SWITCH ? rowsState[key].isChecked : rowsState[key].hasData));
    const classes = filterKeys.length ? 'u-els-margin-top-2x c-table-filter__tags u-els-margin-bottom-1o2' : 'u-els-margin-top c-table-filter__tags';
    if (filterKeys.length) {
      return (
        <div className={classes}>
          {filterKeys.map(key => {
            const config = rowsConfig.find(row => row.key === key);
            return (
              <Suspense fallback={<ELSInlineLoader />} key={key}>
                <TableFilterTag config={config} filter={rowsState[key]} onTagClicked={this.onTagClicked} />
              </Suspense>
            );
          })}

          <button
            type="button"
            className="c-els-button c-els-button--tertiary c-table-filter__reset-filter-btn"
            onClick={this.onResetAllFilterBtnClicked}
            onKeyDown={evt => this.onButtonKeyDown(evt, this.onResetAllFilterBtnClicked)}
          >
            <span className="u-els-font-size-base">Reset All Filters</span>
          </button>
        </div>
      );
    }
    return <div className={classes} />;
  };

  openModal = (): void => {
    this.props.onFilterButtonClicked?.();
    this.setState({
      editingRowsState: cloneDeep(this.props.rowsState),
      modalVisible: true
    });
  };

  render() {
    const { searchKeyword, searchPlaceholder, filterHidden, customClass, children, onSearch, searchHidden, toggleFilterChecked, onToggleFilter, toggleFilterTitle } = this.props;
    const { modalVisible, isPressEsc } = this.state;

    const filterShown = !filterHidden;
    return (
      <div className={classnames('c-table-filter', customClass)}>
        <div className="c-table-filter__filter-container">
          <div className="u-els-padding-top-1o4 u-els-padding-right-1o2 c-table-filter__result">{children}</div>
          {filterShown && (
            <button
              type="button"
              className={classnames({ 'u-els-margin-right-3o4': !searchHidden }, 'c-els-button c-els-button--tertiary c-table-filter__filter-btn')}
              onKeyDown={evt => {
                this.onButtonKeyDown(evt, this.openModal);
              }}
              onClick={this.openModal}
            >
              <ELSIcon name="filter" prefix="gizmo" size="1x" />
              <span className="u-els-margin-left-1o2 u-els-font-size-base">Filters</span>
            </button>
          )}

          {onToggleFilter && (
            <div className="o-els-flex-layout o-els-flex-layout--middle u-els-margin-right u-els-margin-left-1o4 c-table-filter__graded-only">
              <ELSSlideSwitch
                id="toggleGradedAssignments"
                name="toggleGradedAssignments"
                size="small"
                checked={toggleFilterChecked}
                changeHandler={evt => onToggleFilter(evt.target.checked)}
              />
              <span className="u-els-margin-left-1o2">{toggleFilterTitle}</span>
            </div>
          )}

          {!searchHidden && (
            <SearchBar
              placeholder={searchPlaceholder || 'Search'}
              value={searchKeyword}
              handleSearch={evt => {
                onSearch(evt.target.value);
              }}
            />
          )}
        </div>

        {filterShown && (
          <>
            {this.renderTags()}
            <Suspense fallback={<ELSInlineLoader />}>
              <PerformanceReportModal
                className="c-table-filter-modal"
                title={this.props.title || 'Filters'}
                visible={modalVisible}
                onCancel={this.onCancelBtnClicked}
                onOk={this.onApplyFilterBtnClicked}
                footer={this.renderFooter()}
                isDisabledEscEvt={isPressEsc}
                setIsDisabledEscEvtCallback={this.handleSetIsPressEsc}
              >
                <>
                  <div className="u-els-display-block u-els-display-none@mobile">{this.renderDesktopModal()}</div>
                  <div className="u-els-display-none u-els-display-block@mobile">{this.renderMobileModal()}</div>
                </>
              </PerformanceReportModal>
            </Suspense>
          </>
        )}
      </div>
    );
  }
}

export default TableFilter;
