import { isEmpty } from 'lodash';
import { facadeApi, perFacadeApi } from 'helpers/api.helper';
import { getStudentUserFullName } from 'helpers/app.helper';
import { FULFILLED_STATUS } from 'constants/app.constant';
import { PERFORMANCE_LEVEL_LABELS } from 'constants/performanceLevel.constant';
import { CourseSection } from 'reports/cw/models';
import {
  Program,
  Cohort,
  CohortDetail,
  CohortExam,
  CohortStudent,
  CohortTrendLineData,
  CohortExamBarChart,
  ExamStudent,
  StudentDetail,
  StudentTrendLineData,
  CategorySummaryDTO,
  CohortCategoryGroup,
  CategoryExamDataDTO,
  StudentExamCategoryDTO,
  NGNCourseDTO,
  ExamAuthessKeyDTO,
  AssessmentCompletionDTO,
  TrendLineByProgram,
  RelatedUserInfoDTO,
  ExamCategoryPerformanceDTO,
  NGNItemDTO,
  FetchNGNItemPayLoad,
  StudentSummary,
  StudentExam
} from '../models';
import { SCORE_SCALE_CONSTANT } from '../constants/had.constant';

const fetchPrograms = (evolveUsername?: string): Promise<{ data: Array<Program> }> => {
  if (evolveUsername) {
    return perFacadeApi.get(`/hesi/dwh/cohort/programs?evolveUsername=${evolveUsername}`);
  }
  return perFacadeApi.get('/hesi/dwh/cohort/programs');
};

const fetchCohortsByProgramIds = (programIds: Array<number>, evolveUsername?: string): Promise<{ data: Array<Cohort> }> => {
  if (programIds && programIds.length) {
    const programIdsFilter = programIds.filter(Boolean);
    if (!programIdsFilter.length) {
      return Promise.resolve({ data: [] });
    }
    if (evolveUsername) {
      return perFacadeApi.post(`/hesi/dwh/cohort/cohorts?evolveUsername=${evolveUsername}`, { data: programIds });
    }
    return perFacadeApi.post(`/hesi/dwh/cohort/cohorts`, { data: programIds });
  }
  return Promise.resolve({ data: [] });
};

const fetchCohortDetail = ({ programId, cohortId, evolveUsername }): Promise<{ data: CohortDetail }> => {
  if (cohortId && programId) {
    if (evolveUsername) {
      return perFacadeApi.get(`/hesi/cohort/cohortDetail?programId=${programId}&cohortId=${cohortId}&evolveUsername=${evolveUsername}`);
    }
    return perFacadeApi.get(`/hesi/cohort/cohortDetail?programId=${programId}&cohortId=${cohortId}`);
  }
  return Promise.resolve({ data: {} as CohortDetail });
};

const fetchCohortDetailDwh = ({ programId, cohortId, evolveUsername }): Promise<{ data: CohortDetail }> => {
  const baseUrl = '/hesi/dwh/cohort/cohortDetail';
  const params = new URLSearchParams();
  if (cohortId && programId) {
    if (evolveUsername) {
      params.append('evolveUsername', evolveUsername);
    }
    params.append('programId', programId);
    params.append('cohortId', cohortId);
    return perFacadeApi.get(`${baseUrl}?${params}`);
  }
  return Promise.resolve({ data: {} as CohortDetail });
};

const fetchCohortExams = (cohortId: number): Promise<{ data: Array<CohortExam> }> => {
  if (cohortId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/exams?cohortId=${cohortId}`);
  }
  return Promise.resolve({ data: [] });
};

const fetchCohortExamTrendChartDataDwh = (cohortId: number): Promise<{ data: CohortTrendLineData }> => {
  if (cohortId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/exam/trendline?cohortId=${cohortId}`);
  }
  return Promise.resolve({ data: {} as CohortTrendLineData });
};

const fetchCohortExamTrendChartData = (cohortId: number): Promise<{ data: CohortTrendLineData }> => {
  if (cohortId) {
    return perFacadeApi.get(`/hesi/cohort/exam/trendline?cohortId=${cohortId}`);
  }
  return Promise.resolve({ data: {} as CohortTrendLineData });
};

const fetchTrendLineByProgram = (evolveUserName: string, programId: number): Promise<{ data: TrendLineByProgram }> => {
  if (evolveUserName && programId) {
    return perFacadeApi.get(`/hesi/cohort/student/trendlineByProgram?evolveUserName=${evolveUserName}&programId=${programId}`);
  }
  return Promise.resolve({ data: {} as TrendLineByProgram });
};

const fetchTrendLineByProgramDwh = (evolveUserName: string, programId: number): Promise<{ data: TrendLineByProgram }> => {
  if (evolveUserName && programId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/student/trendlineByProgram?evolveUserName=${evolveUserName}&programId=${programId}`);
  }
  return Promise.resolve({ data: {} as TrendLineByProgram });
};

const fetchCohortExamBarChartData = (cohortId: number): Promise<{ data: CohortExamBarChart }> => {
  if (cohortId) {
    return facadeApi.get(`/hesi/app/cohort/student/exam?cohortId=${cohortId}`);
  }
  return Promise.resolve({ data: {} as CohortExamBarChart });
};

const fetchCohortStudents = (cohortId: number): Promise<{ data: Array<CohortStudent> }> => {
  if (cohortId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/students?cohortId=${cohortId}`);
  }
  return Promise.resolve({ data: [] });
};

const fetchExamStudents = (cohortId: number, examId: number): Promise<{ data: Array<ExamStudent> }> => {
  if (cohortId && examId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/examDetail?cohortId=${cohortId}&examId=${examId}`);
  }
  return Promise.resolve({ data: [] });
};

const fetchStudentDetail = (cohortId: number, evolveUserName: string): Promise<{ data: StudentDetail }> => {
  if (cohortId && evolveUserName) {
    return perFacadeApi.get(`/hesi/dwh/cohort/studentDetail?cohortId=${cohortId}&evolveUserName=${evolveUserName}`);
  }
  return Promise.resolve({ data: {} as StudentDetail });
};

const fetchStudentDetailV2 = (cohortId: number, evolveUserName: string): Promise<{ data: StudentDetail }> => {
  if (cohortId && evolveUserName) {
    return perFacadeApi.get(`/hesi/dwh/cohort/v2/studentDetail?cohortId=${cohortId}&evolveUserName=${evolveUserName}`);
  }
  return Promise.resolve({ data: {} as StudentDetail });
};

const fetchStudentDetailByProgram = (evolveUserName: string, programId: number): Promise<{ data: StudentDetail }> => {
  if (evolveUserName && programId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/studentDetailByProgram?evolveUserName=${evolveUserName}&programId=${programId}`);
  }
  return Promise.resolve({ data: {} as StudentDetail });
};

const fetchStudentDetailByProgramV2 = (evolveUserName: string, programId: number): Promise<{ data: StudentDetail }> => {
  if (evolveUserName && programId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/v2/studentDetailByProgram?evolveUserName=${evolveUserName}&programId=${programId}`);
  }
  return Promise.resolve({ data: {} as StudentDetail });
};

const fetchLegacyStudentDetailByProgram = (evolveUserName: string, programId: number): Promise<{ data: StudentDetail }> => {
  if (evolveUserName && programId) {
    return perFacadeApi.get(`/hesi/cohort/studentDetailByProgram?evolveUserName=${evolveUserName}&programId=${programId}`);
  }
  return Promise.resolve({ data: {} as StudentDetail });
};

const fetchDemoStudentDetailByProgram = (evolveUserName: string, programId: number): Promise<{ data: StudentDetail }> => {
  if (evolveUserName && programId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/demoStudentDetailByProgram?evolveUserName=${evolveUserName}&programId=${programId}`);
  }
  return Promise.resolve({ data: {} as StudentDetail });
};

const fetchDemoStudentDetailByProgramV2 = (evolveUserName: string, programId: number): Promise<{ data: StudentDetail }> => {
  if (evolveUserName && programId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/v2/demoStudentDetailByProgram?evolveUserName=${evolveUserName}&programId=${programId}`);
  }
  return Promise.resolve({ data: {} as StudentDetail });
};

const fetchStudentExamCategory = (cohortId: number, evolveUserName: string, categoryGroupId: number): Promise<{ data: StudentExamCategoryDTO[] }> => {
  if (cohortId && evolveUserName && categoryGroupId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/studentExamCategory?cohortId=${cohortId}&evolveUserName=${evolveUserName}&categoryGroupId=${categoryGroupId}`);
  }
  return Promise.resolve({ data: [] });
};

const fetchLegacyStudentExamCategory = (cohortId: number, evolveUserName: string, categoryGroupId: number): Promise<{ data: StudentExamCategoryDTO[] }> => {
  if (cohortId && evolveUserName && categoryGroupId) {
    return perFacadeApi.get(`/hesi/cohort/studentExamCategory?cohortId=${cohortId}&evolveUserName=${evolveUserName}&categoryGroupId=${categoryGroupId}`);
  }
  return Promise.resolve({ data: [] });
};

const fetchStudentTrendChartData = (cohortId: number, evolveUserName: string): Promise<{ data: StudentTrendLineData }> => {
  if (cohortId && evolveUserName) {
    return perFacadeApi.get(`/hesi/cohort/student/trendline?cohortId=${cohortId}&evolveUserName=${evolveUserName}`);
  }
  return Promise.resolve({ data: {} as StudentTrendLineData });
};

const fetchStudentTrendChartDataDwh = (cohortId: number, evolveUserName: string): Promise<{ data: StudentTrendLineData }> => {
  if (cohortId && evolveUserName) {
    return perFacadeApi.get(`/hesi/dwh/cohort/student/trendline?cohortId=${cohortId}&evolveUserName=${evolveUserName}`);
  }
  return Promise.resolve({ data: {} as StudentTrendLineData });
};

const fetchCategorySummary = (cohortId: number, examTypeName: string, categoryGroupId: number): Promise<{ data: Array<CategorySummaryDTO> }> => {
  if (cohortId && examTypeName && categoryGroupId) {
    return perFacadeApi.get(`/hesi/cohort/categorySummary?cohortId=${cohortId}&examTypeName=${examTypeName}&categoryGroupId=${categoryGroupId}`);
  }
  return Promise.resolve({ data: [] });
};

const fetchCategorySummaryDwh = (cohortId: number, examTypeName: string, categoryGroupId: number): Promise<{ data: Array<CategorySummaryDTO> }> => {
  if (cohortId && examTypeName && categoryGroupId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/categorySummary?cohortId=${cohortId}&examTypeName=${examTypeName}&categoryGroupId=${categoryGroupId}`);
  }
  return Promise.resolve({ data: [] });
};

const fetchExamCategoryPerformance = (examId: number, cohortId: number, categoryGroupId: number): Promise<{ data: ExamCategoryPerformanceDTO }> => {
  if (examId && cohortId && categoryGroupId) {
    return perFacadeApi.get(`/hesi/exam/categoryPerformance?examId=${examId}&cohortId=${cohortId}&categoryGroupId=${categoryGroupId}`);
  }
  return Promise.resolve({ data: {} as ExamCategoryPerformanceDTO });
};

const fetchExamCategoryPerformanceDwh = (examId: number, cohortId: number, categoryGroupId: number): Promise<{ data: ExamCategoryPerformanceDTO }> => {
  if (examId && cohortId && categoryGroupId) {
    return perFacadeApi.get(`/hesi/dwh/exam/categoryPerformance?examId=${examId}&cohortId=${cohortId}&categoryGroupId=${categoryGroupId}`);
  }
  return Promise.resolve({ data: {} as ExamCategoryPerformanceDTO });
};

const fetchCategoryGroupList = (cohortId: number): Promise<{ data: Array<CohortCategoryGroup> }> => {
  if (cohortId) {
    return perFacadeApi.get(`/hesi/cohort/categoryGroupList?cohortId=${cohortId}`);
  }
  return Promise.resolve({ data: [] });
};

const fetchCategoryGroupListDwh = (cohortId: number): Promise<{ data: Array<CohortCategoryGroup> }> => {
  if (cohortId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/categoryGroupList?cohortId=${cohortId}`);
  }
  return Promise.resolve({ data: [] });
};

const fetchStudentExamCategoryByProgram = (evolveUserName: string, programId: number, categoryGroupId: number): Promise<{ data: StudentExamCategoryDTO[] }> => {
  if (evolveUserName && programId && categoryGroupId) {
    return perFacadeApi.get(`/hesi/cohort/studentExamCategoryByProgram?evolveUserName=${evolveUserName}&programId=${programId}&categoryGroupId=${categoryGroupId}`);
  }
  return Promise.resolve({ data: [] });
};

const fetchStudentExamCategoryByProgramDwh = (evolveUserName: string, programId: number, categoryGroupId: number): Promise<{ data: StudentExamCategoryDTO[] }> => {
  if (evolveUserName && programId && categoryGroupId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/studentExamCategoryByProgram?evolveUserName=${evolveUserName}&programId=${programId}&categoryGroupId=${categoryGroupId}`);
  }
  return Promise.resolve({ data: [] });
};

const fetchCategoryExamData = (cohortId: number, categoryGroupId: number): Promise<{ data: CategoryExamDataDTO[] }> => {
  if (cohortId && categoryGroupId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/categoryExamData?cohortId=${cohortId}&categoryGroupId=${categoryGroupId}`);
  }
  return Promise.resolve({ data: [] });
};

const fetchExamAuthessKeys = (): Promise<{ data: ExamAuthessKeyDTO[] }> => {
  return perFacadeApi.get('/ngn/examAuthessKeys');
};

const fetchNGNCourse = (cohortId: number): Promise<{ data: NGNCourseDTO[] }> => {
  if (!cohortId) {
    return Promise.resolve({ data: [] });
  }
  return facadeApi.get(`/hesi/app/nclex/cohort/${cohortId}/courseSections`);
};

const fetchNGNItem = ({ cohortId, examId, isStudentLevel, studentEvolveUsername }: FetchNGNItemPayLoad): Promise<{ data: NGNItemDTO[] }> => {
  if (!cohortId && !examId) {
    return Promise.resolve({ data: [] });
  }
  if (isStudentLevel) {
    return perFacadeApi.get(`/hesi/dwh/ngn/student/item?cohortId=${cohortId}&evolveUserName=${studentEvolveUsername}`);
  }
  if (examId) {
    return perFacadeApi.get(`/hesi/dwh/ngn/item?cohortId=${cohortId}&examId=${examId}`);
  }
  return perFacadeApi.get(`/hesi/dwh/ngn/item?cohortId=${cohortId}`);
};

const fetchAssessmentCompletion = (assessmentKey: string, courseSectionIds: string, examGroupIds: string, jwt: string): Promise<{ data: AssessmentCompletionDTO[] }> => {
  if (!assessmentKey || !courseSectionIds) {
    return Promise.resolve({ data: [] });
  }
  return perFacadeApi.get(`/ngn/assessment/completions?assessmentKey=${assessmentKey}&courseSectionIds=${courseSectionIds}&examGroupIds=${examGroupIds}&jwt=${jwt}`);
};

const fetchRelatedUserInfo = (eolsUserId: number): Promise<{ data: RelatedUserInfoDTO[] }> => {
  if (!eolsUserId) {
    return Promise.resolve({ data: [] });
  }
  return facadeApi.get(`/hesi/app/user/${eolsUserId}/related`);
};

const fetchCourseLinkCWAndEHR = async (courses: CourseSection[]): Promise<CourseSection[]> => {
  const results: CourseSection[] = [];
  const promises = courses.map(course => facadeApi.get(`/sherpath/courseSections/${course.id}/evolve/product/v2`));
  const responses = await Promise.allSettled(promises);
  responses.forEach((res, index) => {
    if (res.status === FULFILLED_STATUS && !isEmpty(res?.value?.data)) {
      results.push(courses[index]);
    }
  });
  return results;
};

const fetchStudentDetailByEvolveUsername = (evolveUsername: string): Promise<{ data: StudentDetail }> => {
  if (evolveUsername) {
    return perFacadeApi.get(`/hesi/dwh/cohort/studentDetailByEvolveUsername?evolveUserName=${evolveUsername}`);
  }
  return Promise.resolve({ data: {} as StudentDetail });
};

const fetchStudentDetailByEvolveUsernameV2 = (evolveUsername: string): Promise<{ data: StudentDetail }> => {
  if (evolveUsername) {
    return perFacadeApi.get(`/hesi/dwh/cohort/v2/studentDetailByEvolveUsername?evolveUserName=${evolveUsername}`);
  }
  return Promise.resolve({ data: {} as StudentDetail });
};

const fetchStudentExamCategoryByEvolveUsername = (evolveUsername: string, categoryGroupId: string): Promise<{ data: StudentExamCategoryDTO[] }> => {
  if (evolveUsername && categoryGroupId) {
    return perFacadeApi.get(`/hesi/dwh/cohort/studentExamCategoryByEvolveUsername?evolveUserName=${evolveUsername}&categoryGroupId=${categoryGroupId}`);
  }
  return Promise.resolve({ data: [] });
};

const fetchStudentTrendLineByEvolveUsername = (evolveUsername: string): Promise<{ data: StudentTrendLineData }> => {
  if (evolveUsername) {
    return perFacadeApi.get(`/hesi/dwh/cohort/student/trendlineByEvolveUsername?evolveUserName=${evolveUsername}`);
  }
  return Promise.resolve({ data: {} as StudentTrendLineData });
};

const findMax = (arr: Array<object>, fields: Array<string>): number => {
  return Math.max(...arr.map(item => Math.max(...fields.map(field => item[field] || 0))));
};

const isActualData = (predictedScore: number, actualScore: number): boolean => {
  return !!(!predictedScore && actualScore);
};

const shouldRenderCohortRelatedPage = (storedSelectedCohort: Cohort, cohortIdExtractedFromURL: number): boolean => {
  // Due to the current authentication approach, a user can use their token to get data of cohorts which they do not manage
  // To prevent this issue, we add a guard condition to render this page only when the cohortID matches the selectedCohortID,
  // which is set when a user click to a cohort on the HAD Dashboard home page
  return storedSelectedCohort.cohortId === cohortIdExtractedFromURL;
};

const convertScoreToPerformanceLevel = (score: number): string => {
  const roundedScore = Math.round(score) || 0;
  if (roundedScore < SCORE_SCALE_CONSTANT.MAX_AT_RISK) {
    return PERFORMANCE_LEVEL_LABELS.atRisk;
  }
  if (roundedScore < SCORE_SCALE_CONSTANT.MAX_BELOW_ACCEPTABLE) {
    return PERFORMANCE_LEVEL_LABELS.belowAcceptable;
  }
  if (roundedScore < SCORE_SCALE_CONSTANT.MAX_ACCEPTABLE) {
    return PERFORMANCE_LEVEL_LABELS.acceptable;
  }
  return PERFORMANCE_LEVEL_LABELS.recommended;
};

const getStudentFullName = (student: CohortStudent | StudentDetail): string => (student ? getStudentUserFullName(student) : '');

const fetchStudentSummary = (cohortId: number, evolveUserName: string): Promise<{ data: StudentSummary }> => {
  if (cohortId && evolveUserName) {
    return perFacadeApi.get(`/hesi/dwh/cohort/studentSummary?evolveUserName=${evolveUserName}&cohortId=${cohortId}`);
  }
  return Promise.resolve({ data: {} as StudentSummary });
};

const fetchStudentSummaryV2 = (cohortId: number, evolveUserName: string): Promise<{ data: StudentSummary }> => {
  if (cohortId && evolveUserName) {
    return perFacadeApi.get(`/hesi/dwh/cohort/v2/studentSummary?evolveUserName=${evolveUserName}&cohortId=${cohortId}`);
  }
  return Promise.resolve({ data: {} as StudentSummary });
};

const fetchStudentExams = (cohortId: number, evolveUserName: string): Promise<{ data: Array<StudentExam> }> => {
  if (cohortId && evolveUserName) {
    return perFacadeApi.get(`/hesi/dwh/cohort/studentExams?evolveUserName=${evolveUserName}&cohortId=${cohortId}`);
  }
  return Promise.resolve({ data: [] as Array<StudentExam> });
};

const fetchStudentExamsV2 = (cohortId: number, evolveUserName: string): Promise<{ data: Array<StudentExam> }> => {
  if (cohortId && evolveUserName) {
    return perFacadeApi.get(`/hesi/dwh/cohort/v2/studentExams?evolveUserName=${evolveUserName}&cohortId=${cohortId}`);
  }
  return Promise.resolve({ data: [] as Array<StudentExam> });
};

export {
  fetchAssessmentCompletion,
  fetchCategoryExamData,
  fetchCategoryGroupList,
  fetchCategoryGroupListDwh,
  fetchCategorySummary,
  fetchCategorySummaryDwh,
  fetchCohortsByProgramIds,
  fetchCohortDetail,
  fetchCohortDetailDwh,
  fetchCohortExams,
  fetchCohortExamTrendChartDataDwh,
  fetchCohortExamTrendChartData,
  fetchCohortExamBarChartData,
  fetchCohortStudents,
  fetchCourseLinkCWAndEHR,
  fetchExamAuthessKeys,
  fetchExamStudents,
  fetchDemoStudentDetailByProgram,
  fetchNGNCourse,
  fetchNGNItem,
  fetchPrograms,
  fetchRelatedUserInfo,
  fetchStudentTrendChartData,
  fetchStudentTrendChartDataDwh,
  fetchStudentDetail,
  fetchStudentDetailByProgram,
  fetchStudentExams,
  fetchStudentSummary,
  fetchLegacyStudentDetailByProgram,
  fetchStudentDetailByEvolveUsername,
  fetchExamCategoryPerformance,
  fetchStudentExamCategory,
  fetchLegacyStudentExamCategory,
  fetchStudentExamCategoryByProgram,
  fetchStudentExamCategoryByProgramDwh,
  fetchStudentExamCategoryByEvolveUsername,
  fetchTrendLineByProgram,
  fetchTrendLineByProgramDwh,
  fetchStudentTrendLineByEvolveUsername,
  findMax,
  isActualData,
  shouldRenderCohortRelatedPage,
  convertScoreToPerformanceLevel,
  getStudentFullName,
  fetchStudentDetailV2,
  fetchDemoStudentDetailByProgramV2,
  fetchStudentExamsV2,
  fetchStudentSummaryV2,
  fetchStudentDetailByProgramV2,
  fetchStudentDetailByEvolveUsernameV2,
  fetchExamCategoryPerformanceDwh
};
