import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, orderBy } from 'lodash';
import moment from 'moment';
import { ELSIcon } from 'components/common';
import ListBox, { LIST_BOX_INDEX } from 'components/common/list-box/ListBox';
import withResizeScreen from 'components/common/with-resize-screen/withResizeScreen';
import { SORT_DIRECTION_ENUM } from 'constants/app.constant';
import { DropdownOption, WindowMode } from 'models';
import { onKeyDownHandler } from 'helpers/ui.helper';
import { EngagementSummaryDTO } from 'reports/cw/models';
import { cwActions, cwSelectors } from 'reports/cw/redux/ducks/cw';
import { CW_TABLE_SELECTOR } from 'reports/cw/constants/cw.constant';
import { ASSIGNMENT_TYPES, sortDownIcon, sortLabelByType, sortUpIcon } from 'reports/cw/constants/report.constant';
import { querySelectorWrapper } from 'services/method-wrapper.service';
import ListBoxModal from 'reports/had/components/common/list-box-modal/ListBoxModal';
import ReportContent from 'reports/cw/components/common/report-content/ReportContent';

interface EngagementSummarySortProps {
  engagementSummary: EngagementSummaryDTO;
  windowMode: WindowMode;
}

const iconPrefix = 'gizmo';
export const engagementSummarySortOption = [
  {
    name: 'Assignment Completion',
    value: 'assignmentCompletionRate',
    dependedOptions: [
      { name: sortLabelByType.number.desc, value: SORT_DIRECTION_ENUM.desc, icon: sortDownIcon, iconPrefix },
      { name: sortLabelByType.number.asc, value: SORT_DIRECTION_ENUM.asc, icon: sortUpIcon, iconPrefix }
    ]
  },
  {
    name: 'Total Assignments Assigned',
    value: 'totalAssignmentsByType',
    dependedOptions: [
      { name: sortLabelByType.number.desc, value: SORT_DIRECTION_ENUM.desc, icon: sortDownIcon, iconPrefix },
      { name: sortLabelByType.number.asc, value: SORT_DIRECTION_ENUM.asc, icon: sortUpIcon, iconPrefix }
    ]
  }
];

export const getEngagementSummaryWithAvgLesson = (engagementSummary: EngagementSummaryDTO): EngagementSummaryDTO => {
  let lessonCardData;
  const typeCardsList = cloneDeep(engagementSummary.typeCardsList);
  const filteredTypeCardsList = typeCardsList.filter(typeCard => ![ASSIGNMENT_TYPES.ADAPTIVE_LESSON.id, ASSIGNMENT_TYPES.LESSONS.id].includes(typeCard.assignmentType));
  const lessonEngagement = typeCardsList.find(typeCard => typeCard.assignmentType === ASSIGNMENT_TYPES.LESSONS.id);
  const adaptiveLessonEngagement = typeCardsList.find(typeCard => typeCard.assignmentType === ASSIGNMENT_TYPES.ADAPTIVE_LESSON.id);

  if (lessonEngagement && adaptiveLessonEngagement) {
    const totalAssignmentsByType = lessonEngagement.totalAssignmentsByType + adaptiveLessonEngagement.totalAssignmentsByType;
    const totalStudents = Math.ceil((lessonEngagement.totalStudents + adaptiveLessonEngagement.totalStudents) / 2);
    const totalStudentsPastDue = Math.ceil((lessonEngagement.totalStudentsPastDue + adaptiveLessonEngagement.totalStudentsPastDue) / 2);
    const assignmentCompletionRate =
      (lessonEngagement.assignmentCompletionRate * lessonEngagement.totalAssignmentsByType +
        adaptiveLessonEngagement.assignmentCompletionRate * adaptiveLessonEngagement.totalAssignmentsByType) /
      totalAssignmentsByType;
    const lessonAvgTimeSpent = moment.duration(lessonEngagement.avgTimeSpent).as('milliseconds');
    const adaptiveLessonAvgTimeSpent = moment.duration(adaptiveLessonEngagement.avgTimeSpent).as('milliseconds');
    const avgTimeSpentInMilliseconds =
      lessonAvgTimeSpent > adaptiveLessonAvgTimeSpent ? (lessonAvgTimeSpent - adaptiveLessonAvgTimeSpent) / 2 : (adaptiveLessonAvgTimeSpent - lessonAvgTimeSpent) / 2;
    const avgTimeSpent = moment.duration(Math.round(avgTimeSpentInMilliseconds)).toISOString();
    const percentAvgScore = (lessonEngagement.percentAvgScore + adaptiveLessonEngagement.percentAvgScore) / 2;

    lessonCardData = { ...lessonEngagement, totalAssignmentsByType, totalStudents, totalStudentsPastDue, assignmentCompletionRate, avgTimeSpent, percentAvgScore };
  } else {
    lessonCardData = lessonEngagement || adaptiveLessonEngagement;
  }

  if (lessonCardData) {
    return {
      ...engagementSummary,
      typeCardsList: [lessonCardData, ...filteredTypeCardsList]
    };
  }
  return {
    ...engagementSummary,
    typeCardsList: [...filteredTypeCardsList]
  };
};

export const getSortedEngagementSummary = (engagementSummary: EngagementSummaryDTO, direction: SORT_DIRECTION_ENUM, value: string): EngagementSummaryDTO => {
  const engagementSummaryMapping = getEngagementSummaryWithAvgLesson(engagementSummary);
  const sortedTypeCardList = orderBy(engagementSummaryMapping.typeCardsList, value, direction);
  return {
    ...engagementSummary,
    typeCardsList: sortedTypeCardList
  };
};

const EngagementSummarySort = ({ engagementSummary, windowMode }: EngagementSummarySortProps) => {
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortValue, setSortValue] = useState('assignmentCompletionRate');
  const [iShowSortModal, setIsShowSortModal] = useState(false);
  const engagementSummaryCardAndTableSortInteraction = useSelector(state => cwSelectors.getEngagementSummaryCardAndTableSortInteraction(state));
  const dispatch = useDispatch();

  const changeEngagementSummaryListBox = (option: DropdownOption, listIndex: number) => {
    switch (listIndex) {
      case LIST_BOX_INDEX.PARENT:
        setSortValue(option.value);
        break;
      case LIST_BOX_INDEX.DEPENDENT:
        setSortDirection(option.value);
        break;
      default:
        break;
    }
  };

  const handleSortEngagementSummary = (direction: SORT_DIRECTION_ENUM, value: string) => {
    const sortedEngagementSummary = getSortedEngagementSummary(engagementSummary, direction, value);
    dispatch(cwActions.setEngagementSummary(sortedEngagementSummary));
  };

  useEffect(() => {
    handleSortEngagementSummary(sortDirection as SORT_DIRECTION_ENUM, sortValue);
  }, [sortDirection, sortValue]);

  useEffect(() => {
    if (engagementSummaryCardAndTableSortInteraction) {
      const { field, sortDirection: cardsSortDirection } = engagementSummaryCardAndTableSortInteraction;
      setSortValue(field);
      setSortDirection(cardsSortDirection);
      querySelectorWrapper(CW_TABLE_SELECTOR.engagementSummary)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [engagementSummaryCardAndTableSortInteraction]);

  const showSortModal = () => {
    setIsShowSortModal(true);
  };

  const hideSortModal = () => {
    setIsShowSortModal(false);
  };

  return (
    <ReportContent customClass="u-els-padding@mobile u-els-padding-bottom-none c-cw-engagement-summary-sort">
      <h2 className="u-els-margin-bottom@mobile u-els-margin-bottom@tablet">Engagement Summary By Assignment Type</h2>
      {windowMode.mobile ? (
        <div className="c-cw-engagement-summary-sort__mobile-box" onClick={showSortModal} onKeyDown={evt => onKeyDownHandler(evt, showSortModal)} role="presentation">
          <div className="u-els-margin-right-1o2">Sort by</div>
          <ELSIcon name="chevron-down" size="1x" align="middle" customClass="u-els-color-extended-blue-8" />
        </div>
      ) : (
        <div className="c-cw-engagement-summary-sort__box">
          <div className="c-cw-engagement-summary-sort__box-title">Sort By</div>
          <ListBox inline hasDependent options={engagementSummarySortOption} value={sortValue} dependedValue={sortDirection} changeHandler={changeEngagementSummaryListBox} />
        </div>
      )}
      {iShowSortModal && (
        <ListBoxModal
          hasDependent
          header="Sort by"
          headerIcon="card-expand"
          options={engagementSummarySortOption}
          value={sortValue}
          dependedValue={sortDirection}
          changeHandler={changeEngagementSummaryListBox}
          closeHandler={hideSortModal}
        />
      )}
    </ReportContent>
  );
};

export { EngagementSummarySort as EngagementSummarySortBase };
export default withResizeScreen(EngagementSummarySort);
